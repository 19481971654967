/* eslint-disable no-loop-func */
import { kml, tcx } from "@tmcw/togeojson";
import { DOMParser } from "xmldom";
import moment from "moment";
import axios from "utils/axiosConfig";
import { AppDispatch, store } from "store/store";
import {
  AddFilterFiles,
  DeleteFiles,
  addToUploadFiles,
  addTodistanceAndDuration,
} from "store/reducers/fileReducers";
import { minutesToHHMMSS } from "utils/CommonTSX";
import FitParser from "fit-file-parser";
import {
  CalculateKmlDateandDuration,
  ConverTOUTCTimestamp,
  ConvertToKMLfromKMZ,
  convertTimestampToDateString,
  determineZoom,
  forErrorToast,
  getCordinates,
  getTimeStampDate,
} from "utils/CommonService";
import x2js  from 'x2js'
import gpxParser from 'gpxparser'
import IGCParser from 'igc-parser'
import { DeleteUploadActivity, DeleteUploadActivityTrack } from "./formAction";


// import HSTParser from 'hst-parser'
export const getPromiseCoordinat = async (file: any) => {
  const typeArray = file.name.split(".")
  const type=typeArray[typeArray.length-1]
   
  if (type === "kml") {
    return getPromiseCoordinatKML(file);
  } else if (type === "kmz") {
    return getPromiseCoordinatKMz(file);
  } else if (type === "fit") {
    if(await findIfFitIsGpx(file)){
      // convertExtension(file,".gpx")
     return getPromiseCoordinatGPX(file,true)
    }
    return getPromiseCoordinatFIT(file);
  } else if (type.toLowerCase() === "igc") {
    return getPromiseCoordinatIGC(file);
  } else if (type.toLowerCase() === "hst") {
    return getPromiseCoordinatHST(file);
  } else if (type.toLowerCase() === "tcx") {
    return getPromiseCoordinatTCX(file);
  } else {
      
      if(await findIfFitIsGpx(file)){
        //return getPromiseCoordinatFIT(file,true)
         return getPromiseCoordinatGPX(file)
      }
      return getPromiseCoordinatFIT(file,true)
      //return getPromiseCoordinatGPX(file)
      
  }

};

export async function getPromiseCoordinatGPX(file:any,isGpx?:boolean){

  return new Promise(async (resolve) => {
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      let  gpx_:any = new gpxParser();
      gpx_.parse( e.target.result)
      
     const error=gpx_.xmlSource.getElementsByTagName("parsererror").length
     
    if(error)  {
      resolve({
        cordinates: [],
        center: { lat: 0, lng: 0 },
        duration: 0,
        date: "",
        cumilative_distance: 0,
        total_duration_minutes: 0,
        tracks: [],
      });
      return forErrorToast("The file is corrupt or damaged")
    }

    const tracks = gpx_.tracks
    if (!tracks.length) {
      resolve({
        cordinates: [],
        center: { lat: 0, lng: 0 },
        duration: 0,
        date: "",
        cumilative_distance: 0,
        total_duration_minutes: 0,
        tracks: [],
      });
      return forErrorToast("This file dosen't contain a track");
    }

    const distance = gpx_.tracks.map((trk:any)=> trk.distance ? (trk.distance?.total/1000) : "" ).reduce((total:any,d:any)=> total + d ,0)
    const cordinates = gpx_.tracks.map((e: any)=> e.points).reduce((arr: any, row: any)=> arr.concat(row) ,[]).map((e: any)=> [e.lat,e.lon]).filter((e:any)=> e[0] !== null || e[0] !== null).map((lat_lng:any)=>new window.google.maps.LatLng(lat_lng[0], lat_lng[1]))
    
    if(cordinates.length === 0) return resolve({
      cordinates: [],
      center: { lat: 0, lng: 0 },
      duration: 0,
      date: "",
      cumilative_distance: 0,
      total_duration_minutes: 0,
      tracks: [],
    })
    
    let total_duration_minutes=0
    let formattedTime:any=""
    let cumilative_distance=distance;
      // we onlyneed to find duration when the trk is multiples
     if(tracks.length === 1){
      const isFirstTime = gpx_.tracks[0].points[0]?.time
      const firstDate = isFirstTime ? isFirstTime : "";
      const lastDate=gpx_.tracks[0].points[gpx_.tracks[0].points.length -1]?.time
      if (firstDate && lastDate) {
        const zero = moment(firstDate, "YYYY-MM-DDTHH:mm:00Z");
        const last = moment(lastDate, "YYYY-MM-DDTHH:mm:00Z");
        const diff = last.diff(zero);
        const diffduration = moment.duration(diff);
        formattedTime = minutesToHHMMSS(diffduration.asMinutes());
        total_duration_minutes = diffduration.asMinutes();
      }
     }
      const date = gpx_.metadata?.time ?
         moment(gpx_.metadata.time).format("MM/DD/YYYY")
        : "";

      // here we need to get the timeand travel distance
      const center = cordinates[0];

      //trying to get thetracks and related data
      let trks: any = [];
      if(tracks.length >1){
        formattedTime=""
        trks=tracks.map((e:any)=> ({distance:e.distance? e.distance?.total /1000 :"" })).filter((e:any)=> e !== null || e !== undefined)
      }
      
      // .reduce((acc:any,curr:any,index:number)=>{
      //      if(index % 5===0){
      //       return acc.concat(curr)
      //      }
      //      return acc
      // },[])
      
      resolve({
        cordinates,
        center,
        duration: formattedTime,
        date,
        cumilative_distance,
        total_duration_minutes,
        tracks: trks.length > 1 ? trks : [],
        zoomLevel: determineZoom(cumilative_distance),
        extCorrept:isGpx? ".gpx" : "",
      });
    };

    reader.onerror = () => resolve(false);
    reader.readAsText(file);
  });
}

export async function getPromiseCoordinatTCX(file: any) {
  return new Promise(async (resolve) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const tcxContent = e.target.result;
      const tcxDoc = new DOMParser().parseFromString(tcxContent);

      const geojson: any = tcx(tcxDoc);
      const isFirstTime =
        geojson.features[0].properties.coordinateProperties.times;
      let date = "";
      if (isFirstTime && isFirstTime.length) {
        date = moment(isFirstTime[0]).format("MM/DD/YYYY");
      }

      let cumilative_distance = 0;
      let formattedTime: any = minutesToHHMMSS(geojson.features[0].properties.totalTimeSeconds / 60)
      let total_duration_minutes =  0
      
      // formattedTime = minutesToHHMMSS(diffduration.asMinutes());

      // total_duration_minutes = diffduration.asMinutes();

      // here we need to get the timeand travel distance
      const center = {
        lat: geojson.features[0].geometry.coordinates[0][1],
        lng: geojson.features[0].geometry.coordinates[0][0],
      };

      let trks: any = [];
      if (geojson.features.length > 1) {
        geojson.features.forEach((e: any) => {
          if (e.geometry.type === "LineString") {
            let cumilative_distance_ = e.properties.distanceMeters / 1000;
            let duration_ = e.properties.totalTimeSeconds / 60;
            total_duration_minutes += duration_
            trks.push({
              distance: cumilative_distance_,
              name: e.properties.name,
              duration: duration_,
            });
          }
        });
      }
      //here we have trks with whole trk info
      formattedTime = geojson.features.length >1 ? minutesToHHMMSS(total_duration_minutes) :formattedTime
      
      const allCoordinates = geojson.features
        .map((e: any) =>
          e.geometry.type === "LineString"
            ? e.geometry.coordinates
            : [e.geometry.coordinates]
        )
        .reduce((result: any, row: any) => result.concat(row), [])
        .filter((cord: any) => cord[0] !== null && cord[1] !== null);

      const cordinates: any = allCoordinates.map(
        (lat_lng: any) => new window.google.maps.LatLng(lat_lng[1], lat_lng[0])
      );
    
      resolve({
        cordinates,
        center,
        duration: formattedTime,
        date,
        cumilative_distance,
        total_duration_minutes,
        tracks: trks.length > 1 ? trks : [],
        zoomLevel: determineZoom(cumilative_distance)
      });
    };

    reader.onerror = () => resolve(false);
    reader.readAsText(file);
  });
}

//for kml file
// Completed
export async function getPromiseCoordinatKML(file: any) {
  return new Promise(async (resolve) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const kmlContent = e.target.result;
      const kmlDoc = new DOMParser().parseFromString(kmlContent);
     
      const geojson: any = kml(kmlDoc);
      //const date = geojson.features[0]?.properties?.coordinateProperties?.times.length ? moment(geojson.features[0]?.properties?.coordinateProperties?.times[0]).format("YYYY/MM/DD") : "";
      let allCoordinates: any = [];

      //so we need to find cordinates from each features array cfeate a functionlike that
      let trks = [];
      let dd=""
      if (geojson.features.length) {
        for (let i = 0; i < geojson.features.length; i++) {
          const convertedCord = getCordinates(geojson.features[i]);
        
          //here we get converted codes we need to find distance here by fn
          if (convertedCord.length) {
            let cumilative_distance_ = 0;
            convertedCord.forEach((lat_lng: any, index: number, array: any) => {
              if (lat_lng[0] !== null && lat_lng[1] !== null) {
                allCoordinates.push(lat_lng);

                if (index !== array.length - 1) {
                  const distance = calculateDistance(lat_lng, array[index + 1]);
                  cumilative_distance_ += distance;
                }
              }
            });
            const { file_created_at }: any=CalculateKmlDateandDuration(geojson.features[i])
            if(file_created_at && dd === ""){
              dd=file_created_at
            }
            trks.push({
              distance: cumilative_distance_,
              date: file_created_at ? file_created_at : "",
              duration: "",
            });
          }
          //here iam getting all cordinates
        }
      }

      if (!allCoordinates.length) {
        resolve({
          cordinates: [],
          center: { lat: 0, lng: 0 },
          duration: 0,
          date: "",
          cumilative_distance: 0,
          total_duration_minutes: 0,
          tracks: [],
        });
        return forErrorToast("This file doesn't have any activity");
      }

      // here we need to get the timeand travel distance
      const center = {
        lat: allCoordinates.length ? allCoordinates[0][1] : 0,
        lng: allCoordinates.length ? allCoordinates[0][0] : 0,
      };

      let cumilative_distance = 0;
      let duration = 0;
      let cordinates: any = [];
      if (allCoordinates.length) {
        cordinates = allCoordinates.map(
          (lat_lng: any, index: number, array: any) => {
            if (index !== allCoordinates.length - 1) {
              const distance = calculateDistance(lat_lng, array[index + 1]);
              cumilative_distance += distance;
            }
            return new window.google.maps.LatLng(lat_lng[1], lat_lng[0]);
          }
        );
      }

      //const miles = cumilative_distance * 0.621371;
      resolve({
        cordinates,
        center,
        cumilative_distance,
        date: dd ? convertTimestampToDateString(dd,"MM/DD/YYYY") : "",
        total_duration_minutes: duration,
        tracks: trks.length > 1 ? trks : [],
        zoomLevel: determineZoom(cumilative_distance)
      });
    };

    reader.onerror = () => resolve(false);
    reader.readAsText(file);
  });
}

//for fit file
export async function getPromiseCoordinatFIT(file: any,isFit?:boolean) {
  
  
  return new Promise(async (resolve) => {
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const fitContent = e.target.result;

      
          
            var fitParser = new FitParser({
              force: true,
              speedUnit: "km/h",
              lengthUnit: "km",
              temperatureUnit: "kelvin",
              elapsedRecordField: true,
              mode: "cascade",
            });

            fitParser.parse(fitContent, function (error: any, data: any) {
              // Handle result of parse method
              if (error) {  
              } else {
                const sessions = data.activity.sessions;
                
                let tracks = [];
                if (sessions.length > 1) {
                  // we need to find tracks
                  tracks = sessions.map((e: any) => ({
                    distance: e.total_distance,
                    duration: e.total_elapsed_time,
                    date: e.start_time,
                  }));
                }
      
                const laps = sessions
                  .map((e: any) => e.laps)
                  .reduce((result: any, row: any) => result.concat(row), []);
                const records = laps
                  .map((e: any) => (e.records.length ? e.records : []))
                  .reduce((result: any, row: any) => result.concat(row), []);
                if (!records.length) {
                  resolve({
                    cordinates: [],
                    center: { lat: 0, lng: 0 },
                    duration: 0,
                    date: "",
                    cumilative_distance: 0,
                    total_duration_minutes: 0,
                  });
                  return forErrorToast("This file doesn't have any activity");
                }
      
                const heatMapData = records
                  .filter((rec: any) => rec.position_lat && rec.position_long)
                  .map(
                    (e: any) =>
                      new window.google.maps.LatLng(e.position_lat, e.position_long)
                  );
      
                const distance = sessions
                  .map((e: any) => (e?.total_distance ? e?.total_distance : 0))
                  .reduce(
                    (accumulator: number, currentValue: number) =>
                      accumulator + currentValue,
                    0
                  );
                const duration =
                  sessions
                    .map((e: any) =>
                      e?.total_elapsed_time ? e?.total_elapsed_time : 0
                    )
                    .reduce(
                      (accumulator: number, currentValue: number) =>
                        accumulator + currentValue,
                      0
                    ) / 60;
      
                const center = {
                  lat: sessions.length ? sessions[0].start_position_lat : 0,
                  lng: sessions.length ? sessions[0].start_position_long : 0,
                };
                const date = sessions.length
                  ? moment(sessions[0].start_time).format("MM/DD/YYYY")
                  : "";
                const formattedTime = duration > 1 ? minutesToHHMMSS(duration) : "";
                resolve({
                  cordinates: heatMapData,
                  center,
                  duration: formattedTime,
                  date,
                  cumilative_distance: distance,
                  total_duration_minutes: duration,
                  tracks: tracks,
                  zoomLevel: determineZoom(distance),
                  extCorrept:isFit? ".fit" : "",
                });
              }
            });
    };

    reader.onerror = () => resolve(false);
    reader.readAsArrayBuffer(file);
  });
}

export async function getPromiseCoordinatKMz(file: any) {
  return new Promise(async (resolve) => {
    const kmlContent = await ConvertToKMLfromKMZ(file);
    const kmlDoc = new DOMParser().parseFromString(kmlContent!);

    const geojson: any = kml(kmlDoc);
    //const date = geojson.features[0]?.properties?.coordinateProperties?.times.length ? moment(geojson.features[0]?.properties?.coordinateProperties?.times[0]).format("YYYY/MM/DD") : "";
    let allCoordinates: any = [];

    //so we need to find cordinates from each features array cfeate a functionlike that

    let trks = [];
    if (geojson.features.length) {
      for (let i = 0; i < geojson.features.length; i++) {
        const convertedCord = getCordinates(geojson.features[i]);

        //here we get converted codes we need to find distance here by fn
        if (convertedCord.length) {
          let cumilative_distance_ = 0;
          convertedCord.forEach((lat_lng: any, index: number, array: any) => {
            if (lat_lng[0] !== null && lat_lng[1] !== null) {
              allCoordinates.push(lat_lng);

              if (index !== array.length - 1) {
                const distance = calculateDistance(lat_lng, array[index + 1]);
                cumilative_distance_ += distance;
              }
            }
          });
          trks.push({
            distance: cumilative_distance_,
            date: "",
            duration: "",
          });
        }
        //here iam getting all cordinates
      }
    }

    if (!allCoordinates.length) {
      resolve({
        cordinates: [],
        center: { lat: 0, lng: 0 },
        duration: 0,
        date: "",
        cumilative_distance: 0,
        total_duration_minutes: 0,
        tracks: [],
      });
      return forErrorToast("This file doesn't have any activity");
    }

    // here we need to get the timeand travel distance
    const center = {
      lat: allCoordinates.length ? allCoordinates[0][1] : 0,
      lng: allCoordinates.length ? allCoordinates[0][0] : 0,
    };

    let cumilative_distance = 0;
    let duration = 0;
    let cordinates: any = [];
    if (allCoordinates.length) {
      cordinates = allCoordinates.map(
        (lat_lng: any, index: number, array: any) => {
          if (index !== allCoordinates.length - 1) {
            const distance = calculateDistance(lat_lng, array[index + 1]);
            cumilative_distance += distance;
          }
          return new window.google.maps.LatLng(lat_lng[1], lat_lng[0]);
        }
      );
    }

    //const miles = cumilative_distance * 0.621371;
    resolve({
      cordinates,
      center,
      cumilative_distance,
      date: "",
      total_duration_minutes: duration,
      tracks: trks.length > 1 ? trks : [],
      zoomLevel: determineZoom(cumilative_distance)
    });
  });
}

export async function getPromiseCoordinatIGC(file: any) {
  return new Promise(async (resolve) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      try {
        const igcContent = e.target.result;
        const parsedData: any = IGCParser.parse(igcContent);
        const flightPath = parsedData.fixes.map((fix: any) => [fix.longitude, fix.latitude]);
        // Calculate duration
        const startTime: any = parsedData.fixes[0].time;
        const endTime: any = parsedData.fixes[parsedData.fixes.length - 1].time;
        const diff = (endTime - startTime) / 1000;
        const minutesDuration = diff / 60;
        
        let date = parsedData.date;


        
        let cumilative_distance = 0;
        const center = {
          lat: flightPath[0][1] ?? 0,
          lng: flightPath[0][0] ?? 0,
        };
        const allCoordinates = flightPath.map(
          (e: any, index: number, array: any) => {
            if (index !== array.length - 1) {
              const distance = calculateDistance(e, array[index + 1]);
              cumilative_distance += distance;
            }

            return new window.google.maps.LatLng(e[1], e[0]);
          }
        );
        resolve({
          cordinates: allCoordinates,
          center,
          cumilative_distance,
          duration: minutesDuration,
          date,
          zoomLevel: determineZoom(cumilative_distance)
        });
      } catch (error:any) {
          
        if(error.message){
          resolve({
            cordinates: [],
            center: { lat: 0, lng: 0 },
            duration: 0,
            date: "",
            cumilative_distance: 0,
            total_duration_minutes: 0,
            tracks: [],
          });
          return forErrorToast("The file is corrupt or damaged")
        }
        console.log(error);
        
      }
    };

    reader.onerror = () => resolve(false);
    reader.readAsText(file);
  });
}

export async function getPromiseCoordinatHST(file: any) {
  
  return new Promise(async (resolve) => {
    const reader = new FileReader();
    const X2JSDOM = new x2js()
    reader.onload = async (e: any) => {
      const igcContent = e.target.result;

      const doc:any = X2JSDOM.xml2js(igcContent);
      const History = doc.TrainingCenterDatabase ? doc.TrainingCenterDatabase.History : doc.HistoryDatabase
      const Biking = History.Biking.Run.Lap ? [History.Biking.Run] : History.Biking.Run;

      const laps = Biking.map((e: any) => Array.isArray(e.Lap) ? e.Lap : [e.Lap]).flat();
      const heatMapData = laps.map((e: any) => e.Track.Trackpoint).flat().filter((e: any) => e?.Position).map((crd: any)=> [+crd.Position.LongitudeDegrees,+crd.Position.LatitudeDegrees])
      
      if(!heatMapData.length ){
        resolve({
          cordinates: [],
          center: { lat: 0, lng: 0 },
          duration: 0,
          date: "",
          cumilative_distance: 0,
          total_duration_minutes: 0,
          tracks: [],
        });
        return forErrorToast("This file doesn't have any activity");
      }
      const date = laps.length && laps[0]._StartTime ? moment(laps[0]._StartTime).format("MM/DD/YYYY") : null;
      const center = {lat: +heatMapData[0][1], lng: +heatMapData[0][0]}

      const tracks = laps.map((e: any) => ({ distance: ((+e.DistanceMeters) / 1000), date: "", duration: "" }))
      const total_duration_minutes = laps.map((e: any) => e.TotalTimeSeconds ? +e.TotalTimeSeconds : 0).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0) / 60
      const formattedTime = laps.length === 1 ? minutesToHHMMSS(total_duration_minutes) : null;

      let cumilative_distance = 0;
      const cordinates = heatMapData.map(
        (e: any, index: number, array: any) => {
          if (index !== array.length - 1) {
            const distance = calculateDistance(e, array[index + 1]);
            cumilative_distance += distance;
          }

          return new window.google.maps.LatLng(e[1], e[0]);
        }
      );

      resolve({
        cordinates,
        center,
        duration: formattedTime,
        date,
        cumilative_distance,
        total_duration_minutes: total_duration_minutes,
        tracks,
        zoomLevel: determineZoom(cumilative_distance)
      });
    }
    
    reader.onerror = () => resolve(false);
    reader.readAsText(file);
  });
}

export function calculateDistance(coord1: any, coord2: any) {
  if (
    coord1?.length < 1 ||
    coord2?.length < 1 ||
    !coord1 ||
    !Array.isArray(coord1) ||
    !Array.isArray(coord2)
  ) {
    return 0;
  }
  const [lon1, lat1] = coord1;
  const [lon2, lat2] = coord2;

  const R = 6371; // Earth's radius in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
    Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance; // Distance in kilometers
}

export const findRandomNumber = () => {
  const decimalPlaces = 2; // Adjust this to the number of decimal places you want
  const randomNumber = (Math.random() * 2).toFixed(decimalPlaces);
  return +randomNumber;
};

// function findDuplicateIds(dataArray:any) {
//   let idMap:any = {}; // Use an object to store IDs as keys

//   // Iterate through the array
//   for (let i = 0; i < dataArray.length; i++) {
//       let currentData = dataArray[i];

//       // Check if the ID is already in the map
//       if (idMap[currentData._id]) {
//           // Duplicate ID found, list the data
//           debugger;
//           console.log('Duplicate ID found:', currentData);
//       } else {
//           // Add the ID to the map
//           idMap[currentData.id] = true;
//       }
//   }
// }


const FindallArrayChild=(dataArray:any)=>{
  let count=0
  for (let i = 0; i < dataArray.length; i++) {
          let currentData = dataArray[i];
          if(currentData.file_extension.toLowerCase()==="gpx"){
              count = count + currentData.tracks.length
          }
          if(currentData.file_extension.toLowerCase()==="igc"){
            count =count +1
          }
          if(currentData.file_extension.toLowerCase()==="hst"){
            count =count +currentData.biking_run.length
          }
}

}

export const FetchAllFiles = () => async (dispatch: AppDispatch) => {
  try {
    // let id=localStorage.getItem("id")
    const res: any = await axios.get(`/v1/activities`);
    // ${id? `?user_id=${id}` : ""}

     FindallArrayChild(res.body.listing)
    const data = res;
    
    if (data.status) {
      const body = data.body.listing
  
      const distanceadnduration = data.body;
      const tracks = body.map((e: any) => {
          const { tracks, ...restData } = e;
          const json_file_parent = restData.json_file;
          const json_file_url_parent = restData.json_file_url;
          
          if (restData.file_extension === "gpx") {
            try {
              const file = tracks.filter((e: any) => e.distance).map((ev: any, i: number) => {
                const isTime =
                  ev?.points.length && ev.points[0]?.time && ev.points[1]?.time;
                const start = moment(
                  ev?.points.length && ev.points[0]?.time
                    ? ev.points[0].time._seconds * 1000
                    : new Date()
                );
                const end = moment(
                  ev?.points.length && ev.points[1]?.time
                    ? ev.points[1].time._seconds * 1000
                    : new Date()
                );
                const diff = end.diff(start);
                const diffduration = moment.duration(diff);
                const total_duration_minutes = diffduration.asMinutes();
                return {
                  ...restData,
                  ...ev,
                  title: ev?.title ? ev.title : restData.title,
                  trackTitle: tracks.length === 1 ? "" : "Track " + (i + 1),
                  // file_created_at: restData?.uploaded_at ? ConverTOUTCTimestamp(restData?.uploaded_at) : "",
                  file_created_at: ev.file_created_at ? getTimeStampDate(ev.file_created_at) : ev?.points.length && ev.points[0]?.time ? ev.points[0]?.time : "",
                    // ev?.points.length && ev.points[0]?.time
                    //   ? ev.points[0]?.time
                    //   : "",
                  json_file_parent,
                  json_file_url_parent,
                  total_duration_minutes: isTime
                    ? Math.abs(total_duration_minutes)
                    : 0,
                  _id: restData._id + i,
                  main_id: restData._id,
                  child_id:ev?.child_virtual_id? ev.child_virtual_id : null
                };
              });
              return file;
            } catch (err) {
              console.log(err)
            }
          }

          /// child id remains
          if (restData.file_extension === "fit") {
            try {
              const { sessions, ...restSession } = restData;
              const fitData = sessions.map((session: any, i: number) => ({
                ...restSession,
                ...session,
                points: [
                  { 
                    "lon": +session.laps[0].lap.start_position_long, 
                    "lat": +session.laps[0].lap.start_position_lat
                  },
                  { 
                    "lon": +session.laps[session.laps.length - 1].lap.start_position_long, 
                    "lat": +session.laps[session.laps.length - 1].lap.start_position_lat
                  },
                ],
                // file_created_at: restData?.uploaded_at ? ConverTOUTCTimestamp(restData?.uploaded_at) : "",
                file_created_at: session.file_created_at ? getTimeStampDate(session.file_created_at) : session.start_time ? session.start_time : "",
                json_file_parent,
                json_file_url_parent,
                total_duration_minutes: session.total_timer_time
                  ? session.total_timer_time / 60
                  : 0,
                _id: restData._id + i,
                main_id: restData._id,

                trackTitle: sessions.length === 1 ? "" : "Track " + (i + 1),
                // trackTitle: "",
                distance: session.total_distance ? session.total_distance : 0,
                child_id: session?.child_virtual_id ? session?.child_virtual_id : 0
              }));
              return fitData;
            } catch (err) {
              console.log(err)
            }
          }

          if (restData.file_extension === "kml") {
            try {
              const kmlFiles = tracks.features.filter((kmlData: any) => kmlData?.geometry?.type === "Polygon" || kmlData?.geometry?.geometries?.find((ev: any) => ev.type === "LineString")).map((kmlData: any, i: number) => {
                
                const isLineString = kmlData?.geometry?.geometries?.find((ev: any) => ev.type === "LineString")
                const isPolyLine = kmlData?.geometry?.geometries?.find((ev: any) => ev.type === "Polygon")
                const LineString = isLineString ? isLineString : isPolyLine
                
                const {total_duration_minutes, file_created_at}:any=CalculateKmlDateandDuration(kmlData)
                const child_id = isLineString ? isLineString.child_virtual_id : 0;
                
                return ({
                ...restData,
                title: isLineString?.title ? isLineString.title : restData.title,
                tags: isLineString?.tags ? isLineString.tags : restData.tags,
                activity_type: isLineString?.activity_type ? isLineString.activity_type : restData.activity_type,
                points: [
                  { 
                    lat: isPolyLine ? +LineString.coordinates[0].longitude : +LineString.coordinates[0].latitude,
                    lon: isPolyLine ?   +LineString.coordinates[0].latitude: +LineString.coordinates[0].longitude 
                  },
                  { 
                    lat: isPolyLine ? +LineString.coordinates[1].longitude : +LineString.coordinates[1].latitude,
                    lon: isPolyLine ?   +LineString.coordinates[1].latitude: +LineString.coordinates[1].longitude 
                  }
                ],
                // file_created_at: restData?.uploaded_at ? ConverTOUTCTimestamp(restData?.uploaded_at) : "",
                file_created_at: kmlData?.geometry?.geometries[0]?.file_created_at ? getTimeStampDate(kmlData?.geometry?.geometries[0]?.file_created_at) : file_created_at,
                json_file_parent,
                json_file_url_parent,
                total_duration_minutes: total_duration_minutes,
                _id: restData._id + i,
                main_id:restData._id,
                trackTitle: tracks.features.length === 1 ? "" : "Track " + (i + 1),
                
                json_file_url: LineString ? LineString?.json_file_url : "",
                distance: LineString ? LineString?.distance : 0,
                child_id:child_id 
              })});
              return kmlFiles;
            } catch (err) {
              console.log(err)
            }
          }

          if (restData.file_extension.toLowerCase() === "igc") {
            try {
              // let timestamp = restData?.fixes?.fixes.length
              //   ? restData?.fixes?.fixes[0]?.timestamp
              //   : null;
              // let _seconds = Math.floor(timestamp / 1000);
              return [
                {
                  ...restData,
                  title: restData?.fixes?.title ? restData?.fixes?.title : restData?.title,
                  tags: restData?.fixes?.tags ? restData?.fixes?.tags : restData?.tags,
                  activity_type: restData?.fixes?.activity_type ? restData?.fixes?.activity_type : restData?.activity_type,
                  points: [
                    {
                      lat: +restData.fixes.fixes[0].latitude,
                      lon: +restData.fixes.fixes[0].longitude
                    },
                    {
                      lat: +restData.fixes.fixes[1].latitude,
                      lon: +restData.fixes.fixes[1].longitude
                    }
                  ],
                  trackTitle: "",
                  file_created_at: restData.fixes.file_created_at ? getTimeStampDate(restData.fixes.file_created_at) : restData.fixes.date ? ConverTOUTCTimestamp(restData.fixes.date) : "",
                  //timestamp ? { _seconds } : "",
                  distance: restData.total_distance_kilometre,
                  main_id:restData._id,
                  total_duration_minutes:restData?.fixes.total_duration ? restData?.fixes.total_duration : 0,
                  child_id: 0,
                },
              ];
            } catch (err) {
              console.log(err)
            }
          }


          const getTimeStamp = (dateTime: string) => { 
            const date = new Date(dateTime);
            const milliseconds = date.getTime();
            const _seconds = Math.floor(milliseconds / 1000);
            const remainingMilliseconds = milliseconds % 1000;
            const _nanoseconds = remainingMilliseconds * 1e6;
            return { _seconds, _nanoseconds }
          }
          if (restData.file_extension.toLowerCase() === "tcx") {
            try {
              const { activities, ...resActivity } = restData
              const tracks = activities.filter((activity: any) => {
                if(activity.Track) {
                  if(Array.isArray(activity.Track)) {
                    const Trackpoint = activity.Track.map((ev: any) => ev.Trackpoint).flat();
                    if(Trackpoint && Trackpoint.length > 1) return true;
                    else return false;
                  }
                  return true
                };
                if(!Array.isArray(activity.Lap) && activity.Lap.Track) {
                  if(Array.isArray(activity.Lap.Track)) {
                    const Trackpoint = activity.Lap.Track.map((ev: any) => ev.Trackpoint).flat().filter((ev: any) => ev?.Position);
                    if(Trackpoint && Trackpoint.length > 1) return true;
                    else return false;
                  }
                  if(activity.Lap.Track.Trackpoint) {
                    const Trackpoint = activity.Lap.Track.Trackpoint.filter((ev: any) => ev?.Position);
                    if(Trackpoint && Trackpoint.length > 1) return true;
                    else return false;
                  }
                  return false
                };
                if(Array.isArray(activity.Lap) && activity?.Lap[0].Track && activity?.Lap[activity?.Lap.length - 1].Track) {
                  const TrackpointStart = activity?.Lap[0].Track.Trackpoint.filter((ev: any) => ev?.Position);
                  const TrackpointEnd = activity?.Lap[activity?.Lap.length - 1].Track.Trackpoint.filter((ev: any) => ev?.Position);
                  if(TrackpointStart && TrackpointStart.length > 1 && TrackpointEnd && TrackpointEnd.length > 1) {
                    return true;
                  }
                }

                return false
              }).map((activity: any, i: number) => {
                return ({
                ...resActivity,
                title: activity?.title ? activity.title : resActivity.title,
                tags: activity?.tags ? activity.tags : resActivity.tags,
                activity_type: activity?.activity_type ? activity.activity_type : resActivity.activity_type,
                points: [
                  {
                    lon: activity.Track ? 
                            Array.isArray(activity.Track) ?
                            activity.Track.map((ev: any) => ev.Trackpoint).flat().filter((ev: any) => ev?.Position)[0].Position?.LongitudeDegrees :
                          +activity.Track.Trackpoint.filter((ev: any) => ev?.Position)[0].Position?.LongitudeDegrees : 
                          !Array.isArray(activity.Lap) ? 
                          +activity.Lap.Track.Trackpoint.filter((ev: any) => ev?.Position)[0].Position?.LongitudeDegrees : 
                          +activity?.Lap[0].Track.Trackpoint.filter((ev: any) => ev?.Position)[0].Position.LongitudeDegrees,
                    lat: activity.Track ? 
                            Array.isArray(activity.Track) ?
                            activity.Track.map((ev: any) => ev.Trackpoint).flat().filter((ev: any) => ev?.Position)[0].Position?.LatitudeDegrees :
                        +activity.Track.Trackpoint.filter((ev: any) => ev?.Position)[0].Position?.LatitudeDegrees : 
                        !Array.isArray(activity.Lap) ? 
                        +activity.Lap.Track.Trackpoint.filter((ev: any) => ev?.Position)[0].Position?.LatitudeDegrees : 
                        +activity?.Lap[0].Track.Trackpoint.filter((ev: any) => ev?.Position)[0].Position.LatitudeDegrees 
                  },
                  {
                    lon: activity.Track ? 
                            Array.isArray(activity.Track) ?
                            activity.Track.map((ev: any) => ev.Trackpoint).flat().filter((ev: any) => ev?.Position)[1].Position?.LongitudeDegrees :

                          +activity.Track.Trackpoint.filter((ev: any) => ev?.Position)[1].Position?.LongitudeDegrees : 
                          !Array.isArray(activity.Lap) ? 
                          +activity.Lap.Track.Trackpoint.filter((ev: any) => ev?.Position)[1].Position?.LongitudeDegrees : 
                          activity?.Lap[activity?.Lap.length - 1].Track ? 
                          +activity?.Lap[activity?.Lap.length - 1].Track.Trackpoint.filter((ev: any) => ev?.Position)[1].Position.LongitudeDegrees : 
                          +activity?.Lap[0].Track.Trackpoint.filter((ev: any) => ev?.Position)[0].Position.LongitudeDegrees,

                    lat: activity.Track ? 
                            Array.isArray(activity.Track) ?
                            activity.Track.map((ev: any) => ev.Trackpoint).flat().filter((ev: any) => ev?.Position)[1].Position?.LatitudeDegrees :

                          +activity.Track.Trackpoint.filter((ev: any) => ev?.Position)[1].Position?.LatitudeDegrees : 
                          !Array.isArray(activity.Lap) ? 
                          +activity.Lap.Track.Trackpoint.filter((ev: any) => ev?.Position)[1].Position?.LatitudeDegrees : 
                          activity?.Lap[activity?.Lap.length - 1].Track ? +activity?.Lap[activity?.Lap.length - 1].Track.Trackpoint.filter((ev: any) => ev?.Position)[1].Position.LatitudeDegrees : 
                          +activity?.Lap[0].Track.Trackpoint.filter((ev: any) => ev?.Position)[0].Position.LatitudeDegrees
                  }
                ],
                // file_created_at: restData?.uploaded_at ? ConverTOUTCTimestamp(restData?.uploaded_at) : "",
                // file_created_at: activity.Track && activity?._StartTime ? getTimeStamp(activity?._StartTime) : activity?.Lap?._StartTime ? getTimeStamp(activity?.Lap?._StartTime) : "",
                file_created_at: activity.file_created_at ? getTimeStampDate(activity.file_created_at) : 
                          activity.Track ?
                          Array.isArray(activity.Track) ?
                          getTimeStamp(activity?._StartTime) :
                          getTimeStamp(activity?._StartTime) : 
                        !Array.isArray(activity.Lap) ? 
                        getTimeStamp(activity.Lap._StartTime) : 
                        getTimeStamp(activity?.Lap[0]._StartTime),
                json_file_parent,
                json_file_url_parent,
                total_duration_minutes: activity.Track ?
                      Array.isArray(activity.Track) ?
                      (+activity?.TotalTimeSeconds)/60 :
                      (+activity.TotalTimeSeconds)/60 : 
                    !Array.isArray(activity.Lap) ? 
                    (+activity.Lap.TotalTimeSeconds)/60 : 
                    +activity?.Lap.map((ev: any) => (+ev.TotalTimeSeconds)/60).reduce((accumulator: number, currentValue: number) => { return accumulator + currentValue }, 0),
                // total_duration_minutes: activity.Track && activity.TotalTimeSeconds ? ((+activity.TotalTimeSeconds)/60) : activity?.Lap?.TotalTimeSeconds ? ((+activity?.Lap?.TotalTimeSeconds)/60) : 0,
                _id: restData._id + i,
                trackTitle: activities.length === 1 ? "" : "Track " + (i + 1),
                json_file_url: activity.json_file_url,
                distance: activity.Track ?
                      Array.isArray(activity.Track) ?
                      (+activity?.DistanceMeters)/1000 :
                      (+activity.DistanceMeters)/1000 : 
                    !Array.isArray(activity.Lap) ? 
                    (+activity.Lap.DistanceMeters)/1000 : 
                    +activity?.Lap.map((ev: any) => (+ev.DistanceMeters)/1000).reduce((accumulator: number, currentValue: number) => { return accumulator + currentValue }, 0),
                // distance: activity.Track && activity.DistanceMeters ? ((+activity?.DistanceMeters)/1000) : activity?.Lap?.DistanceMeters ? ((+activity?.Lap?.DistanceMeters)/1000) : 0,
                maximum_heart_rate: activity.Track && activity.MaximumHeartRateBpm ? +activity.MaximumHeartRateBpm.Value : activity?.Lap?.MaximumHeartRateBpm && activity?.Lap?.MaximumHeartRateBpm?.Value ? +activity?.Lap?.MaximumHeartRateBpm?.Value : 0,
                main_id: restData._id,
                child_id:activity?.child_virtual_id ? activity.child_virtual_id : null,
              })})
              return tracks;
            } catch (err) {
              console.log(err)
            }
          }
       //Ad:bike.Lap.Track.Trackpoint !== undefined && bike.Lap.Track.Trackpoint.length

       //MQ:bike.Lap.Track.Trackpoint.length
          if (restData.file_extension.toLowerCase() === "hst") {
            try {
              const { biking_run, ...restBiking_run } = restData;
              const compareTracks = (bike: any) => {
                if(bike?.bikingAr?.Lap?.Track?.Trackpoint) {
                  bike = bike?.bikingAr
                }
                if(Array.isArray(bike?.Lap?.Track)) {
                  const Trackpoint = bike.Lap.Track.map((e: any) => e.Trackpoint ? e.Trackpoint : e).flat().filter((e: any) => e.Position);
                  bike.Lap.Track = { Trackpoint };
                }
                return bike
              }
              const biking = biking_run.map((bike: any) => compareTracks(bike)).filter((bike: any) => bike.Lap.Track.Trackpoint.filter((ev:any) => ev.Position).length > 1).sort((a:any,b:any)=> a.Lap?._StartTime && b.Lap?._StartTime ? getTimeStamp(a?.Lap?._StartTime)._seconds - getTimeStamp(b?.Lap?._StartTime)._seconds : -1).map((bike: any, i: number) => {
                const getFilterTrackPoints = bike?.Lap?.Track?.Trackpoint
                return ({
                ...restBiking_run,
                title: bike?.title ? bike.title : restBiking_run.title,
                tags: bike?.tags ? bike.tags : restBiking_run.tags,
                activity_type: bike?.activity_type ? bike.activity_type : restBiking_run.activity_type,
                points: [
                  {
                    lon: +getFilterTrackPoints[0].Position.LongitudeDegrees,
                    lat: +getFilterTrackPoints[0].Position.LatitudeDegrees
                  },
                  {
                    lon: +getFilterTrackPoints[getFilterTrackPoints.length - 1].Position.LongitudeDegrees,
                    lat: +getFilterTrackPoints[getFilterTrackPoints.length - 1].Position.LatitudeDegrees
                  },
                ],
                // file_created_at: restData?.uploaded_at ? ConverTOUTCTimestamp(restData?.uploaded_at) : "",
                file_created_at: bike.file_created_at ? getTimeStampDate(bike.file_created_at) : bike?.Lap?._StartTime ? getTimeStamp(bike?.Lap?._StartTime) : "",
                json_file_parent,
                json_file_url_parent,
                total_duration_minutes: bike?.Lap?.TotalTimeSeconds ? ((+bike?.Lap?.TotalTimeSeconds)/60) : 0,
                _id: restData._id + i,
                main_id: restData._id,
                trackTitle: biking_run.length === 1 ? "" : "Track " + (i + 1),
                json_file_url: bike.json_file_url,
                distance: bike?.Lap?.DistanceMeters ? ((+bike?.Lap?.DistanceMeters)/1000) : 0,
                max_heart_rate: bike?.Lap?.MaximumHeartRateBpm ? +bike?.Lap?.MaximumHeartRateBpm : 0,
                maximum_speed: bike?.Lap?.MaximumSpeed ? +bike?.Lap?.MaximumSpeed : 0,
                avg_heart_rate: bike?.Lap?.AverageHeartRateBpm ? +bike?.Lap?.AverageHeartRateBpm : 0,
                child_id: bike?.child_virtual_id ? bike?.child_virtual_id  : null
              })})
              return biking
            } catch (err) {
              console.log(err)
            }
          }

          return [];
        })
        .reduce((result: any, row: any) => result.concat(row), []);
        
      dispatch(addToUploadFiles(tracks));
      dispatch(addTodistanceAndDuration(distanceadnduration));
      return tracks;
    }
  } catch (error) {
    console.log(error);
  }
};

export const filteringData = (filterData: any) => (dispatch: AppDispatch) => {
  try {
    return new Promise((resolve) => {
      const { uploadedGPXFiles } = store.getState().uploadfile;

      let fData = uploadedGPXFiles.filter((file: any) => {
        return searchingFunction(file, filterData);
      });

      if (fData.length) {
        dispatch(AddFilterFiles(fData));
      }
      resolve(true);
    });
  } catch (error) { }
};

const searchingFunction = (file: any, filterObject: any) => {
  if (
    filterObject.activityType &&
    filterObject.activityType !== file.activity_type
  ) {
    return false;
  }
  if (filterObject.tag && !file.tags.includes(filterObject.tag)) {
    return false;
  }
  if (filterObject.dateduration) {
    const startTime = new Date(filterObject.dateduration[0]);
    const endTime = new Date(filterObject.dateduration[1]);
    const t1 = new Date(file.created_at._seconds * 1000);
    if (startTime < t1 && t1 > endTime) {
      return false;
    }
  }

  return true;
  //if(filterObject)
};


const findIfFitIsGpx=async(file:any)=>{
  return new Promise(async (resolve) => {
      
    const reader = new FileReader();
    
    reader.onload = async (e: any) => {
          const fitContent = e.target.result;

          let dv=new DataView(fitContent)
          const signature = String.fromCharCode(

            dv.getUint8(1),
            dv.getUint8(2),
            dv.getUint8(3),
            dv.getUint8(4),
            dv.getUint8(5),
            dv.getUint8(6),
            dv.getUint8(7),
            dv.getUint8(8),
            dv.getUint8(9),
            dv.getUint8(11),
            dv.getUint8(12),
            dv.getUint8(13),
            dv.getUint8(14),
            dv.getUint8(15),
            dv.getUint8(16),
            dv.getUint8(17),
            dv.getUint8(18),
            dv.getUint8(19),
            dv.getUint8(20),
            dv.getUint8(21),
            dv.getUint8(22),
            dv.getUint8(23),
            dv.getUint8(24),
            dv.getUint8(25),
            dv.getUint8(26),
            dv.getUint8(27),
            dv.getUint8(28),
            dv.getUint8(29),

            dv.getUint8(30),
            dv.getUint8(31),
            dv.getUint8(32),
            dv.getUint8(33),
            dv.getUint8(34),
            dv.getUint8(35),
            dv.getUint8(36),
            dv.getUint8(37),
            dv.getUint8(38),
            dv.getUint8(39),

            dv.getUint8(40),
            dv.getUint8(41),
            dv.getUint8(42),
            dv.getUint8(43),
            dv.getUint8(44),
            dv.getUint8(45),
            dv.getUint8(46),

            
            dv.getUint8(47),
            dv.getUint8(48),
            dv.getUint8(49),
            dv.getUint8(50),
            dv.getUint8(51),
            dv.getUint8(52),
            dv.getUint8(53),
            dv.getUint8(54),
            dv.getUint8(55),
            dv.getUint8(56),
            dv.getUint8(57),
            dv.getUint8(58),
            dv.getUint8(59),
            dv.getUint8(60),

            dv.getUint8(61),
            dv.getUint8(62),
            dv.getUint8(63),
            dv.getUint8(64),
            dv.getUint8(65),
            dv.getUint8(66),
            dv.getUint8(67),
            dv.getUint8(68),
            dv.getUint8(69),
            dv.getUint8(70),


          );
          
          if(signature.includes("gpx")){
             return resolve(true)
          }
          
          return resolve(false)
        }
        reader.onerror = () => resolve(false);
        reader.readAsArrayBuffer(file);
      })
}

export function convertExtension(file:any,newExt:any){
  const modifiedFileName = file.name.replace(/\.[^/.]+$/, newExt);
                const modifiedFile = new File([file], modifiedFileName, {
                    type: file.type
                });

    return modifiedFile;
    
}

export const deleteActivityTrackAction=(id:string,childId:string)=>async(dispatch:AppDispatch)=>{
  try {
    //setDeleteLoad(true)
     const res= await DeleteUploadActivityTrack(id,childId)
     
    if(res){
      dispatch(DeleteFiles({main_id: id , child_id: childId}))
      return true
      //navigate('/map')
    }
  } catch (error) {
    return false
  }
}

export const deleteActivityAction=(id:string)=>async(dispatch:AppDispatch)=>{
  try {
    
   const res=await DeleteUploadActivity(id)
   
    if(res){
      dispatch(DeleteFiles({main_id:id,child_id:null}))
      return true
    }
    return false
  
  } catch (error) {
    return false
  }
}