export const minutesToHHMMSS = (minutes:number) => {
  const months = Math.floor(minutes / 43200); // 43200 minutes in a month (30 days)
  const remainingMinutes1 = minutes % 43200;
  
  const days = Math.floor(remainingMinutes1 / 1440); // 1440 minutes in a day (24 hours)
  const remainingMinutes2 = remainingMinutes1 % 1440;
  
  const hours = Math.floor(remainingMinutes2 / 60);
  const remainingMinutesInHour = remainingMinutes2 % 60;
  const seconds = 0;
  
  const formattedMonths = months ?  String(months.toFixed(0)).padStart(2, '0') : ""; 
  const formattedDays = days ?  String(days.toFixed(0)).padStart(2, '0') : ""; 
  const formattedHours = hours ?  String(hours.toFixed(0)).padStart(2, '0') : "";
  const formattedMinutes =  String(remainingMinutesInHour.toFixed(0)).padStart(2, '0') ;
  const formattedSeconds = String(seconds).padStart(2, '0');

  if(!formattedMonths && !formattedDays && formattedHours)  {
    return  <p>{formattedHours}:{formattedMinutes}:{(formattedSeconds)}</p>
  }
  else if(!formattedMonths && formattedDays && formattedHours)  {
    return <p>{formattedDays} <small>{+formattedDays > 1 ? " days" : " day"}</small>, {formattedHours} <small>{+formattedHours > 1 ? " Hours" : " Hour"}</small></p>
  }
  else if(formattedMonths && formattedDays) {
    return <p>{formattedMonths}<small> Month</small>, {formattedDays}<small>{+formattedDays > 1 ? ' days' :" day"}</small></p>
  } else if (!formattedMonths && formattedDays && !formattedHours) {
    return <p>{formattedDays}<small>{+formattedDays > 1 ? ' days' :" day"}</small></p>
  }
  return <p>00:{formattedMinutes}:{(formattedSeconds)}</p>
}


