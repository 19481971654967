/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import pureAxios from "axios";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { FetchAllFiles } from "store/actions/mapActions";
import { RootState } from "store/store";
import { chunkArray, convertLatlngToPixel, setLayer, setPathColor } from "utils/CommonService";
import { GoogleMap, MarkerClusterer, Marker, OverlayView  } from '@react-google-maps/api';
// import PolylineComponent from "./PolylineComponent";
import { BOUNDS, GmapStyle, SORTINGFILES, Sort_Type, clusterStyles } from "utils/constant";
import CustomPopup from "./CustomPopup";
import { addSelectFile, clearAllMapData } from "store/reducers/fileReducers";
// import HeatmapComponent from "./HeatmapComponent";
import MapBox from "./MapBox";

// import moreactiviti from "assets/images/activities.png";

let cluster = "https://firebasestorage.googleapis.com/v0/b/savuti-c0969.appspot.com/o/Africa%20Toes%20Original.png?alt=media&token=e843b6bc-b88e-4802-9d96-e3b9d527c6ba";
declare global {
  interface Window {
    google: any;
    MarkerClusterer: any;
  }
}
// interface GoogleMapType {
//   setLoading: Function;
//   selectedFile: string;
//   setSelectedFile: Function;
//   clusterShow:boolean;
//   // setSelected:Function;
// }

function GoogleMapCom({ setLoading, setSelectedFile,clusterShow, clusterLatLng, setClusterLatLng, clusterMarkerIds, setClusterMarkerIds, showPopup, setShowPopup,sortFile,SortType }: any) {
  const containerStyle = {
    width: '100%'
  };
  
  const dispatch = useAppDispatch();
  const { filteredFiles, clusterKey, files, uploadedGPXFiles } = useAppSelector((state: RootState) => state.uploadfile);

  const [zoom, setZoom] = useState(2);
  const [center, setCenter] = useState({ lat: -3.745, lng: -38.523 });

  const mapRef = useRef<google.maps.Map | null>(null);
  const heatREf = useRef<any>(null);
  const mapBox = useRef<any>(null);

  const { token } = useAppSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(FetchAllFiles()).then(async (res: any) => {
      setLoading(false);
      const activities = setPathColor(res);
      activities.sort((a: any, b: any) => a.strockRang - b.strockRang);
      const chunkSize = 100;
      const arrayOfChunks = chunkArray(activities, chunkSize);
      mapBox.current.on("load", async () => {
        for(let i=0; arrayOfChunks.length > i; i++) {
          try {
            const batch = arrayOfChunks[i];
            const promises = batch.map((obj: any) => pureAxios.get(obj.json_file_url));
            const responses = await Promise.all(promises);

            const files = [];
            for (let i = 0; i < responses.length; i++) {
              const res = responses[i];
              const activitie = batch[i];
              const allpoints = getPointsNCluister(res, activitie.file_extension);
              // const points = douglasPeucker(allpoints, 0.001);
              
              const heartRate = activitie.file_extension.toLowerCase() === "tcx" ? 
                                  res?.data?.Track ? 

                                    Array.isArray(res?.data?.Track) ? res.data.Track.map((e: any) => e.Trackpoint).flat().filter((e: any) => e?.Position && e?.HeartRateBpm?.Value).map((e: any) => +e?.HeartRateBpm?.Value) : res?.data?.Track.Trackpoint.filter((e: any) => e?.Position && e?.HeartRateBpm?.Value).map((e: any) => +e?.HeartRateBpm?.Value) : 
                                  
                                    Array.isArray(res?.data?.Lap) ? res?.data?.Lap.map((e: any) => e.Track.Trackpoint).flat().filter((e: any) => e?.Position && e?.HeartRateBpm?.Value).map((e: any) => +e?.HeartRateBpm?.Value) : res.data.Lap.Track.Trackpoint.filter((e: any) => e?.Position && e?.HeartRateBpm?.Value).map((e: any) => +e?.HeartRateBpm?.Value) :
                                [];
              const heartRateHst = activitie.file_extension.toLowerCase() === "hst" ? res.data.Lap.Track.Trackpoint.filter((e: any) => e?.Position && e?.HeartRateBpm).map((e: any) => +e?.HeartRateBpm) : [];
              const file = {
                position: {
                  lat: allpoints[0][1],
                  lng: allpoints[0][0],
                },
                id: activitie._id,
                activity_type:activitie.activity_type,
                heatMapPoints: allpoints,
                graphPoints: allpoints,
                title: activitie.title,
                total_distance_kilometre: activitie.distance,
                total_duration_minutes: activitie.total_duration_minutes,
                url: activitie?.url,
                file_extension: activitie.file_extension,
                heartRate,
                heartRateHst,
                trackTitle: activitie?.trackTitle,
                file_path: activitie.file_path,
                number: activitie.number,
                strockRang: activitie.strockRang,
                date: activitie.file_created_at?._seconds ? new Date(activitie.file_created_at._seconds * 1000 ).toDateString() : "",
                created_at:activitie.created_at?._seconds ? new Date(activitie.created_at._seconds * 1000 ).toDateString() :""
              };
              files.push(file);
            }
            dispatch({ type: "uploadfile/updateBatchFiles", payload: files });
            // setMapBoxPolyline(files);
          } catch (err) {
            console.log(err);
          }
        }
      })
    })
    .catch(() => {
      setLoading(false);
    });
    return () => {
      dispatch(clearAllMapData())
      heatREf.current?.setData([])
      heatREf.current?.setMap([])
    }
  }, [token]);

  useEffect(() => {
    const filteredIds = filteredFiles.map((e: any) => e._id);
    const filterFiles = files.filter((e: any) => filteredIds.includes(e.id));

    if((filteredFiles.length !== uploadedGPXFiles.length || files.length === uploadedGPXFiles.length) && mapBox.current) {
      if (mapBox.current.getSource('source-one')) {
        if (mapBox.current.getLayer('source-oneline')) {
          mapBox.current.removeLayer('source-oneline');
          mapBox.current.removeSource("source-one");
        }
      }
      if (mapBox.current.getSource('source-two')) {
        if (mapBox.current.getLayer('source-twolayer')) {
          mapBox.current.removeLayer('source-twolayer');
          mapBox.current.removeSource("source-two");
        }
      }
      
      if (mapBox.current.getSource('source-three')) {
        if (mapBox.current.getLayer('source-threeline')) {
          mapBox.current.removeLayer('source-threeline');
        }
        if (mapBox.current.getLayer('source-threelayer')) {
          mapBox.current.removeLayer('source-threelayer');
          mapBox.current.removeSource("source-three");
        }
      }

      if (mapBox.current.getSource('source-four')) {
        if (mapBox.current.getLayer('source-fourline')) {
          mapBox.current.removeLayer('source-fourline');
        }
        if (mapBox.current.getLayer('source-fourlayer')) {
          mapBox.current.removeLayer('source-fourlayer');
          mapBox.current.removeSource("source-four");
        }
      }
    }

    setMapBoxPolyline(filteredFiles.length === uploadedGPXFiles.length ? filterFiles : filterFiles.slice(-100));

  },[filteredFiles, files, uploadedGPXFiles])

  const setMapBoxPolyline = (polyline: any) => {
    const layerOne = polyline.filter((e: any) => e.strockRang <= 25);
    const layerTwo = polyline.filter((e: any) => e.strockRang > 25 && e.strockRang <= 50);
    const layerThree = polyline.filter((e: any) => e.strockRang > 50 && e.strockRang <= 75);
    const layerFoure = polyline.filter((e: any) => e.strockRang > 75 && e.strockRang <= 100);
    if(layerOne.length) {
      // Layer One
      const lineBottomlayer = [
        1, 0.2,
        5, 1,
        10, 1,
        15, 2
      ]
      setLayer(
        'source-one', 
        layerOne, 
        mapBox.current, 
        null, 
        '', 
        lineBottomlayer
      );
    }

    if(layerTwo.length) {
      // Layer Two
      const lineToplayer = [
        1, 0.8,
        3, 1,
        5, 1,
        14, 2,
        15, 4,
        16, 7,
        17, 10,
        18, 11
      ]
      setLayer(
        'source-two', 
        layerTwo, 
        mapBox.current, 
        window.location.origin + "/level_two.png", 
        'source-two-line-pattern', 
        null,
        lineToplayer
      );
    }

    if(layerThree.length) {
      // Layer Three
      const lineBottomlayer = [
        1, 0.5,
        3, 0.5,
        5, 0.8,
        14, 1,
        15, 3,
        16, 6,
        17, 7,
        18, 9
      ]
      const lineToplayer = [
        1, 0.8,
        3, 1,
        5, 1,
        14, 2,
        15, 4,
        16, 7,
        17, 10,
        18, 11
      ]
      setLayer(
        'source-three', 
        layerThree, 
        mapBox.current, 
        window.location.origin + "/level_three.png",
        'source-three-line-pattern',
        lineBottomlayer,
        lineToplayer
      );
    }

    if(layerFoure.length) {
      // Layer Foure
      const lineBottomlayer = [
        1, 1,
        3, 2,
        5, 3,
        14, 3,
        15, 7,
        16, 11,
        17, 14,
        18, 16
      ]
      const lineToplayer = [
        1, 1,
        3, 2,
        5, 3,
        14, 3,
        15, 7,
        16, 11,
        17, 14,
        18, 20
      ]
      setLayer(
        'source-four',
        layerFoure,
        mapBox.current,
        window.location.origin + "/level_foure.png",
        'source-four-line-pattern',
        lineBottomlayer,
        lineToplayer
      );
    }
  }

  const getPointsNCluister = (res: any, type: string) => {
    if (type.toLowerCase() === "gpx") {
      return res.data.points.map((e: any) => [e.lon, e.lat, e.ele]);
    }
    if (type.toLowerCase() === "fit") {
      const laps = res.data.laps;
      return laps
        .map((e: any) =>
          e.records.length
            ? e.records
                .map((ev: any) => [ev.position_long, ev.position_lat, ev.altitude])
                .filter((ev: any) => ev[0] && ev[1])
            : [
                [e.start_position_lat, e.start_position_long],
                [e.end_position_lat, e.end_position_long],
              ]
        )
        .reduce((result: any, row: any) => result.concat(row), []);
    }
    if (type.toLowerCase() === "kml") {
      
      if(res.data.type==="Polygon"){
        return res.data.coordinates.flat().map((e:any)=> [e[1],e[0]])
      }
      return res.data.type === "LineString" ? res.data.coordinates : [];
    }
    if (type.toLowerCase() === "igc") {
      return res.data.fixes.map((e: any) => [e.longitude, e.latitude, e.gpsAltitude]);
    }
    if (type.toLowerCase() === "tcx") {
      if(res?.data?.Track) {
        if(Array.isArray(res?.data?.Track)) {
          return res.data.Track.map((e: any) => e.Trackpoint).flat().filter((e: any) => e.Position).map(
            (e: any) => [+e.Position.LongitudeDegrees, +e.Position.LatitudeDegrees, e.AltitudeMeters ? +e.AltitudeMeters : 0]
          );
        }
        return res.data.Track.Trackpoint.filter((e: any) => e.Position).map(
          (e: any) => [+e.Position.LongitudeDegrees, +e.Position.LatitudeDegrees, e.AltitudeMeters ? +e.AltitudeMeters : 0]
        );
      }
      if(Array.isArray(res?.data?.Lap)) {
        return res?.data?.Lap.map((e: any) => e.Track.Trackpoint).flat().filter((e: any) => e.Position).map(
          (e: any) => [+e.Position.LongitudeDegrees, +e.Position.LatitudeDegrees, e.AltitudeMeters ? +e.AltitudeMeters : 0]
        );
      }
      return res.data.Lap.Track.Trackpoint.filter((e: any) => e.Position).map(
        (e: any) => [+e.Position.LongitudeDegrees, +e.Position.LatitudeDegrees, e.AltitudeMeters ? +e.AltitudeMeters : 0]
      );
    }
    if (type.toLowerCase() === "hst") {
      const lap = !res.data.Lap.Lap ? res.data.Lap : null;
      if(Array.isArray(res.data.Lap.Track)) {
        const Trackpoint = res.data.Lap.Track.map((e: any) => e.Trackpoint ? e.Trackpoint : e).flat().filter((e: any) => e.Position);
        res.data.Lap.Track = { Trackpoint };
      }
      if (lap){
        return res.data.Lap.Track.Trackpoint.flat()
          .filter((e: any) => e?.Position)
          .map((crd: any) => [
            +crd.Position.LongitudeDegrees,
            +crd.Position.LatitudeDegrees,
            crd.AltitudeMeters ? +crd.AltitudeMeters : 0
          ]);
      }
    }
    return [];
  };

  function handleZoomChange() {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      if(currentZoom) setZoom(currentZoom);
    }
  }
  
  function handleMapLoad(map: google.maps.Map) {
    mapRef.current = map;
    heatREf.current = new google.maps.visualization.HeatmapLayer({
      data: [],
      maxIntensity: 25,
      radius: 5,
      opacity: 0.8,
      //gradient:gradientColors
    })
    
    const LatLng = {
      lat: 41.85,
      lng: -87.65
    }

    const chicago = new google.maps.LatLng(LatLng.lat, LatLng.lng);

    convertLatlngToPixel(chicago, mapRef?.current?.getZoom());

  }

  const handleDragEnd = () => {
    if (mapRef.current) {
      const centerLatLng = mapRef.current.getCenter();
      if (centerLatLng) {
        const newCenter = {
            lat: centerLatLng.lat(),
            lng: centerLatLng.lng()
        };
        setCenter(newCenter);
      }
    }
  };

  function compareDatesDescending(a:any, b:any) {
    const decision= sortFile === SORTINGFILES.UPLOADED_DATE ? 1 :0
    const dateA = new Date(a.getTitle().split("||")[decision]);
    const dateB = new Date(b.getTitle().split("||")[decision]);
  
    // Check if either date is an empty string
    if (a.getTitle() === "" && b.getTitle() !== "") return 1;
    if (a.getTitle() !== "" && b.getTitle() === "") return -1;
  
    // Compare the dates in descending order
    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;
    return 0;
  }

  function compareDatesAscending(a:any,b:any){
    const decision= sortFile === SORTINGFILES.UPLOADED_DATE ? 1 :0
    const dateA = new Date(a.getTitle().split("||")[decision]);
    const dateB = new Date(b.getTitle().split("||")[decision]);
  
    // Check if either date is an empty string
    if (a.getTitle() === "" && b.getTitle() !== "") return 1;
    if (a.getTitle() !== "" && b.getTitle() === "") return -1;
  
    // Compare the dates in descending order
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  }

  const handleClusterClick = (cluster: any) => {
    //here sorting the whole marker based upon date that created
    let sortedArrayByDate=null
    if(SortType=== Sort_Type.DESC){
       sortedArrayByDate= cluster.getMarkers().sort(compareDatesDescending)
    }
    else{
      sortedArrayByDate= cluster.getMarkers().sort(compareDatesAscending)
    }
    
    // here adding the markerIds Of Sorted Array
    const allMarkersIds = sortedArrayByDate.map((marker: any) => marker.get('label'));
    setClusterMarkerIds(allMarkersIds)
    const clusterPosition = cluster.getCenter();
    setClusterLatLng(clusterPosition);
    setShowPopup(true);
    // dispatch(addSelectFile(null))
    let first = filteredFiles.find((e:any)=>e._id === allMarkersIds[0])
    setSelectedFile(first._id)
    dispatch(addSelectFile({...first,id:first._id}))
  };

  return (
    <div className="mapDiv">
      <img className="activeImage" src={require("assets/images/activities.png")} alt="activities" />
      {/* {clusterShow === "polyline" ?  */}
      <div className="mainMapContainer" style={{ zIndex: clusterShow === "polyline" ? "9999" : "9" }}>
        <MapBox map={mapBox} />
      </div>
      {/* : */}
      <div className="mainMapContainer" style={{ zIndex: clusterShow === "polyline" ? "9" : "9999" }}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            onDragEnd={handleDragEnd}
            center={center}
            zoom={zoom}
            onLoad={handleMapLoad}
            onZoomChanged={handleZoomChange}
            //onBoundsChanged={handleZoomChange}
            id="map"
            options={{
              zoomControl: (showPopup && clusterShow !== "cluster") || !showPopup || !clusterShow,
              scrollwheel: (showPopup && clusterShow !== "cluster") || !showPopup || !clusterShow,
              disableDoubleClickZoom: (showPopup && clusterShow !== "cluster") || showPopup ,
              keyboardShortcuts: (showPopup && clusterShow !== "cluster") || !showPopup,
              minZoom: 2,
              maxZoom: 19,
              styles: GmapStyle.style,
              restriction: {
                latLngBounds: BOUNDS,
                strictBounds: true,
              },
          }}
          >

            {
              clusterShow ==="cluster" ?  <MarkerClusterer
              key={clusterKey}
              options={{
                  styles: clusterStyles,
                  imagePath: cluster,
                  minimumClusterSize: 1
              }}
              zoomOnClick={false}
              onClick={handleClusterClick}
          >
              {
              clusterer => {
                const clusterData = filteredFiles.map((e: any) => ({position : {lat: +e.points[0].lat, lng: +e.points[0].lon}, id: e._id, date: e.file_created_at._seconds ? new Date(e.file_created_at._seconds * 1000).toDateString() : "", created_at: e.created_at._seconds ? new Date(e.created_at._seconds * 1000).toDateString() : ""}));
                return clusterData.map((polylineData: any, index: number) => (
                  <Marker
                      key={index}
                      position={polylineData.position}
                      clusterer={clusterer}
                      label={polylineData.id ? polylineData.id : ""}
                      title={`${polylineData?.date}||${polylineData.created_at}`}
                  />
              )
              )}
              }
          </MarkerClusterer>
          : null
            }

            {/* {
              clusterShow === "polyline" ? <PolylineComponent key={clusterKey} zoom={zoom} />  : null
            } */}

            {/* {
              clusterShow === "heatmap" ? <HeatmapComponent gMap={mapRef.current} heatMap={heatREf.current} /> : null
            } */}
            {/* {zoom < 3 ? (
              <MarkerClusterer
                  options={{
                      styles: clusterStyles,
                      imagePath: cluster,
                      minimumClusterSize: 1
                  }}
                  zoomOnClick={false}
                  onClick={handleClusterClick}
              >
                  {
                  clusterer => 
                  
                  files.filter((e: any) => filteredFiles.find((ev: any) => ev._id === e.id) ? true : false).map((e: any) => ({position : {lat: +e.position.lat, lng: +e.position.lng}, id: e.id}) ).map((polylineData: any, index: number) => (
                          <Marker
                              key={index}
                              position={polylineData.position}
                              clusterer={clusterer}
                              label={polylineData.id ? polylineData.id : ""}
                          />
                      ))
                  }
              </MarkerClusterer>
          ) : (
            <PolylineComponent />
          )} */}
          {showPopup && clusterLatLng && clusterShow==="cluster" ? (
                    <OverlayView
                        position={clusterLatLng}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <CustomPopup 
                          clusterMarkerIds={clusterMarkerIds} 
                          setShowPopup={setShowPopup} 
                          setClusterMarkerIds={setClusterMarkerIds}
                          setSelectedFile={setSelectedFile}
                          map={mapRef.current}
                        />
                    </OverlayView>
                ): null}
          </GoogleMap>
      </div>
      {/* }  */}
    </div>
  );
}

const GoogleMapMemo = React.memo(GoogleMapCom);

export default GoogleMapMemo;



    




