
// const isDevelopment = process.env.NODE_ENV === "development";
let url = "https://us-central1-savuti-c0969.cloudfunctions.net/apiMarch";
// if (isDevelopment) {
//   url= "http://127.0.0.1:7001/savuti-c0969/us-central1/api"
// }

const config = {
  API_URL: url
}
export default config