/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import moment from "moment";
import { useState, useRef, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { addSelectFile } from "store/reducers/fileReducers";
import { RootState } from "store/store";
import { minutesToHHMMSS } from "utils/CommonTSX";

const CustomPopup = ({ clusterMarkerIds, setShowPopup, setClusterMarkerIds, setSelectedFile,map }: any) => {
    const dispatch = useAppDispatch();
    const { filteredFiles, files, pointLocation } = useAppSelector((state: RootState) => state.uploadfile);
    const [index, setIndex] = useState(0);
    const [center, setCenter] = useState({ lat: -3.745, lng: -38.523 });
    const mapRef = useRef<google.maps.Map | null>(null);
    const data = filteredFiles.find((e: any) => clusterMarkerIds[index] === e._id);
    useEffect(() => {
        if(data) setCenter({lat: data.points[0].lat, lng: data.points[0].lon})
        if(clusterMarkerIds.length === 1) {
            setIndex(0)
        }
    },[data, setClusterMarkerIds])
    
    if(!data) return <></>


    const containerStyle = {
        width: '100%',
        height: "288px"
    };

    const polyline = files.find((e: any) => e.id === clusterMarkerIds[index]);
    
    if(polyline?.position && clusterMarkerIds?.length === 1 && data ){
        
        const pos=new google.maps.LatLng(data.points[0].lat, data.points[0].lon)
       if (!map.getBounds().contains(pos)) {
        
        // The location is outside the bounds, so pan the map to it
        map.panTo(pos);
      }
    
    }
    

    const handleDragEnd = () => {
        if (mapRef.current) {
          const centerLatLng = mapRef.current.getCenter();
          if (centerLatLng) {
            const newCenter = {
                lat: centerLatLng.lat(),
                lng: centerLatLng.lng()
            };
            setCenter(newCenter);
          }
        }
    };

    function handleMapLoad(map: google.maps.Map) {
        mapRef.current = map;
        setCenter({lat: data.points[0].lat, lng: data.points[0].lon})
    }

    
    
    return (
        <>
        <div className="customWindow">
            {clusterMarkerIds.length > 1 ?
            <>
                <button className="arrowLeft" onClick={() => {
                    setIndex(index === 0 ? clusterMarkerIds.length-1 : (index-1))
                    const i = index === 0 ? clusterMarkerIds.length-1 : (index-1)
                    
                    const polyline = files.find((e: any) => e.id === clusterMarkerIds[i])
                    setSelectedFile(polyline.id)
                    dispatch(addSelectFile(polyline))
                }}>
                    
                </button>
                <button className="arrowRight" onClick={() => {
                    setIndex(index === clusterMarkerIds.length-1 ? 0 : index+1)
                    const i = index === clusterMarkerIds.length-1 ? 0 : index+1
                    
                    const polyline = files.find((e: any) => e.id === clusterMarkerIds[i])
                    setSelectedFile(polyline.id)
                    dispatch(addSelectFile(polyline))
                }}>
                    
                </button>
            </>
             : null}
            <div className="crooss" onClick={() => {
                setShowPopup(false);
                setClusterMarkerIds([]);
            }}></div>
            <div
                style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "7px 32px 0px 6px",
                alignItems: "center",
                }}
            >
                <h4>
                    {data?.activity_type} 
                </h4>
                <a href={data.url} className="downloadFile">File</a>
            </div>
            <div className="elevator pl-2 pr-2 pt-0 pb-0">
                {data?.distance ? (
                <label>
                    Distance:{" "}
                    <span>{data?.distance?.toFixed(2)} km</span>
                </label>
                ) : null}
                {data?.file_created_at ? 
                <label>
                    Date:{" "}
                    <span>{moment.unix(data.file_created_at._seconds).format('MM-DD-YYYY')}</span>
                </label>
                : null}
                {/* start */}
                {/* {data?.created_at ? 
                <label>
                    Uploaded Date:{" "}
                    <span>{moment.unix(data.created_at._seconds).format('MM-DD-YYYY')}</span>
                </label>
                : null} */}
                {/* end */}
                {data?.total_duration_minutes > 1 ? (
                <label>
                    Duration:{" "}
                    <span>{minutesToHHMMSS(data?.total_duration_minutes)}</span>
                </label>
                ) : null}
                
            </div>
            <div
                style={{ width: "20rem", height: "18rem", position: "relative" }}
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    onLoad={handleMapLoad}
                    onDragEnd={handleDragEnd}
                    zoom={12}
                    options={{
                        draggable: true,
                        zoomControl: false,
                        scrollwheel: true,
                        disableDoubleClickZoom: true,
                        keyboardShortcuts: false,
                        disableDefaultUI: true
                    }}
                >
                {polyline ? 
                    <Polyline
                        path={polyline.graphPoints.map((e: any) => ({lat: e[1], lng: e[0]}))}
                        options={{ strokeColor: '#FF0000' }}
                    /> : null
                }
                {pointLocation !== null && (
                    <Marker 
                        position={{lat: pointLocation[1], lng: pointLocation[0]}} 
                        icon={{
                            url: "https://firebasestorage.googleapis.com/v0/b/savuti-c0969.appspot.com/o/rec.png?alt=media&token=30bd52ec-2551-47cb-8ffe-57c75a5513d0&_gl=1*13k6q5*_ga*MTUzOTk3NDkyNC4xNjkyNDQ3OTE3*_ga_CW55HF8NVT*MTY5NjUxNjE4OC45NC4xLjE2OTY1MTYyNTIuNjAuMC4w",
                            scaledSize: new google.maps.Size(15, 15),
                            anchor: new window.google.maps.Point(7.5, 7.5)
                        }}
                    >
                    </Marker>
                )}
                </GoogleMap>
            </div>
            </div>
        </>
    );
};

export default CustomPopup;