import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import Cookies from "js-cookie";

// const token = Cookies.get("token");
// const refresh_token = Cookies.get("refresh_token");
// const user = Cookies.get("user");

const initialState: any = {
  activeTags: [],
  allActiveTags:[]
};

export const TagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    addToActiveTags: (state, action: PayloadAction<any>) => {
      state.activeTags = [...action.payload];
    },
    addSelectedFile: (state, action: PayloadAction<any>) => {
      state.selectedFile = action.payload;
    },
    AddNewTags: (state: any, action: PayloadAction<any>) => {
     
      state.activeTags = [action.payload, ...state.activeTags];
    },
    DeleteTagsAction: (state, action: PayloadAction<any>) => {
      state.activeTags = state.activeTags.filter(
        (tag: any) => tag.id !== action.payload
      );
    },
    UpdateTagAction: (state, action: PayloadAction<any>) => {
      state.activeTags[action.payload.index] = {
        ...state.activeTags[action.payload.index],
        active:action.payload.status
      }
    },
    AddAllActiveTags:(state, action:PayloadAction<any>) => {
      state.allActiveTags=action.payload
    }
  },
});
export const {
  addSelectedFile,
  addToActiveTags,
  AddNewTags,
  DeleteTagsAction,
  UpdateTagAction,
  AddAllActiveTags
} = TagsSlice.actions;

export default TagsSlice.reducer;

export const getALLFetchedActiveTags=(state:any)=>(
  state.filter((tag:any)=> +tag.active === 1)
)


 