/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from "react";
import SkeltonFile from "../skelton";
import {  addSelectFile, addWholeMarker } from "store/reducers/fileReducers";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { debounce, forSuccessToast, isDialogOpen } from "utils/CommonService";
import { minutesToHHMMSS } from "utils/CommonTSX";
import {  RootState } from "store/store";
import { Link } from "react-router-dom";
import moment from "moment";
import { Delete, DriveFileRenameOutlineOutlined } from "@mui/icons-material"
import { deleteActivityAction, deleteActivityTrackAction } from "store/actions/mapActions";
import EditDateModal from "./EditDateModal";
import { updateCommonLoader } from "store/reducers/layoutReducer";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import ResizeObserver from "rc-resize-observer"
import { Sort_Type } from "utils/constant";

export default function FilePage({ loading, filteredFiles, setSelectedFile, sortBy, SelectedFile,SortType, clusterLatLng, setClusterLatLng, clusterMarkerIds, setClusterMarkerIds, showPopup, setShowPopup }: any) {
  let showData = filteredFiles;

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  )

    
  function compareDatesDescending(a:any, b:any) {
    // Check if either date is an empty string
    if (a[sortBy]=== "" && b[sortBy]!== "") return 1;
    if (a[sortBy] !== "" && b[sortBy] === "") return -1;
    // Compare the dates in descending order
    // if (dateA > dateB) return -1;
    // if (dateA < dateB) return 1
    return b[sortBy]?._seconds - a[sortBy]?._seconds
    
  }

  const [child_id,setChildId]=useState(null)
  const [mainId,setMainId]=useState(null)
  const [date,setDate]=useState(null)
  const [showModal,setShowModal]=useState(false)
  const [id,setId]=useState(null)

  function compareDatesAscending(a:any,b:any){
    // Check if either date is an empty string
    if (a[sortBy]=== "" && b[sortBy]!== "") return 1;
    if (a[sortBy] !== "" && b[sortBy] === "") return -1;
  
    // Compare the dates in descending order
    // if (dateA < dateB) return -1;
    // if (dateA > dateB) return 1;
    return a[sortBy]?._seconds - b[sortBy]?._seconds
  
  }
  
  const sortedData = [...showData].sort(SortType === Sort_Type.DESC ? compareDatesDescending : compareDatesAscending)

  return (
    <>
    <div className="file-container">
      {showData.length && !loading ? 
      <AutoSizer>
        {({ width, height }: any) => (
          <List
            width={width}
            height={height}
            rowHeight={cache.current.rowHeight}
            deferredMeasurementCache={cache.current}
            rowCount={showData.length}
            className="scroll"
            rowRenderer={({ key, index, style, parent }) => {
              const item = sortedData[index];
              return (
                <CellMeasurer
                  key={key}
                  cache={cache.current}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  {({ measure }) => {
                    return (
                    <div key={key} style={style}>
                      <ResizeObserver onResize={(ev) => {
                        if(ev.height) measure();
                      }}>
                        <div style={{padding: "0 5px 10px 0"}}>
                          <GpxFile 
                            key={index} 
                            metadata={item} 
                            setSelectedFile={setSelectedFile} 
                            SelectedFile={SelectedFile} 
                            clusterLatLng={clusterLatLng}
                            setClusterLatLng={setClusterLatLng}
                            clusterMarkerIds={clusterMarkerIds}
                            setClusterMarkerIds={setClusterMarkerIds}
                            showPopup={showPopup}
                            setShowPopup={setShowPopup}
                            allData={showData}
                            setShowModal={setShowModal}
                            setMainId={setMainId}
                            setChildId={setChildId}
                            setDate={setDate}
                            setId={setId}
                            measure={measure}
                          />
                        </div>
                      </ResizeObserver>
                    </div>
                  )}}</CellMeasurer>
              ) }}
            />
          
        )}
      </AutoSizer>
      : null }

      {loading ? (<SkeltonFile />) : null}
      {!showData.length && !loading ? <div className="noData"><p>No Data Available</p></div> : null}
    </div>
    <div className="mt-1"><b>Total Distance: {showData.map((e: any) => e.distance).reduce((total: any, d: any)=> total + d ,0).toFixed(2)} km </b></div>
    <EditDateModal showModal={showModal} setShowModal={setShowModal} child_id={child_id} mainId={mainId} id={id} date={date}  />
    </>
  );
}

const GpxFile = ({ allData, metadata, setSelectedFile,SelectedFile, setId,setDate,setClusterLatLng, setClusterMarkerIds, setShowPopup,setMainId,setChildId,setShowModal }: any) => { 
  const dispatch = useAppDispatch();
  const { files } = useAppSelector((state: RootState) => state.uploadfile);
  const [open, setOpen] = useState(false);
  const { token } = useAppSelector((state: RootState) => state.auth);
  const [deleteLoad,setDeleteLoad] = useState(false)
  const id = localStorage.getItem("id")
  
  // const navigate=useNavigate()
  const handleMouseOverFile = () => {
    dispatch(addWholeMarker({whole_Markers:[],center:{}}));
    dispatch(addSelectFile({...metadata,id:metadata._id}))
    setSelectedFile(metadata._id);
    const data = files.find((e: any) => metadata._id === e.id);

    if(data) {
      setClusterLatLng({ lat: data.position.lat, lng: data.position.lng })
      setShowPopup(true);
      setClusterMarkerIds([metadata._id]);
    }
  }

  
  const handleDateChange=(e:any)=>{
    e.stopPropagation();
    setMainId(metadata?.main_id)
    setChildId(metadata?.child_id)
    setDate(metadata?.file_created_at)
    setId(metadata?._id)
    setShowModal(true)
  }
  
  const isButtonShow = (meta: any) => {
    if(meta.file_extension === "kml") return false
    if(meta.file_extension === "gpx") {
      return false
      // if(metadata?.elevation?.min || metadata?.elevation?.avg || metadata?.elevation?.max) return true 
    }
    if(meta.file_extension === "fit") {
      if(metadata?.max_heart_rate || metadata?.avg_speed || metadata?.avg_heart_rate) return true 
    }
    if(meta.file_extension === "hst") {
      if(metadata?.max_heart_rate || metadata?.maximum_speed || metadata?.avg_heart_rate) return true 
    }
    if(metadata.file_extension === "tcx" && metadata.maximum_heart_rate) {
      return true
    }
    return false
  }
  

  const DeleteTrackPopup = async (id: string, childId: string)=>{
    setDeleteLoad(true)
    const res:any=await dispatch(deleteActivityTrackAction(id, childId))
      
      if(res){
        setDeleteLoad(false)
      
        return forSuccessToast("Activity Deleted Succesfully")
      }
      setDeleteLoad(false)
    
  }

  const DeleteActivityPopup=async(id:string)=>{
    setDeleteLoad(true)
    const res= await dispatch(deleteActivityAction(id))
      
      if(res){
        setDeleteLoad(false)
        
        return forSuccessToast("Activity Deleted Succesfully")
      }
      setDeleteLoad(false)
    
  }

  const handleConfirm = async (id:string, childId:string)=>{
    isDialogOpen.onNext({
      open: true,
      data: {
        title: "Confirm Delete",
        message: "Are you sure you want to delete?",
      },
      cancelText: "Cancel",
      confirmText: "Okay",
      onConfirm: async () => {
        dispatch(updateCommonLoader(true));
        if(childId){
          await DeleteTrackPopup(id, childId)
        }
        else{
          await DeleteActivityPopup(id)
        }
        dispatch(updateCommonLoader(false));
      },
      onCancel: () => isDialogOpen.onNext(false),
    });
  };

  return (
    <>
    <div 
      key={metadata?._id} 
      className={`files ${SelectedFile?.id === metadata._id ? "selectFile" : "" }`} 
      onClick={debounce(handleMouseOverFile,0)} 
      style={{cursor:"pointer"}}
      id={metadata?._id}
    >
      <div className={`fileContainer mqContainer ${isButtonShow(metadata) ? "isbutton" : "noButton"} ${open ? "mb-1" : ""}`}>
      {
         token && id 
        //  !=="OLqN74oGVtQAw7Dmbyn8sEd9Gvy1"
          ? 
         <button 
            disabled={deleteLoad}
            onClick={(e) => {
              e.stopPropagation();
              const isNoTrack = allData.filter((ev: any) => ev.main_id === metadata.main_id && ev._id !== metadata._id)
              handleConfirm(metadata?.main_id , isNoTrack.length ? metadata?.child_id : null)
            }}
            className="deleteButton"
          >
        <Delete />
      </button>: null
    }
        <div 
          className="meta-container"
        >
          <span className="files-name">{metadata?.title.toUpperCase()} 
          {/* {metadata.trackTitle ? `- ${metadata.trackTitle}` : ""}  */}
          </span>
          <span className="files-name"> 
            {metadata?.file_created_at ? moment(metadata?.file_created_at._seconds * 1000).format("MM/DD/YYYY") : "--/--/----"} 
            <DriveFileRenameOutlineOutlined className="editDate" onClick={handleDateChange} />
          </span>
          
          <div className="durationDistance">
            {metadata?.total_duration_minutes > 1 ? minutesToHHMMSS(metadata?.total_duration_minutes) : null}
            {metadata?.total_duration_minutes > 1 && metadata?.distance ? <span>-</span> : null}
            {metadata.distance ? <span className="pr-0 pl-0">{metadata?.distance.toFixed(2)} Km</span> : null}
          </div>
          
        </div>
        <div style={{display:"flex",flexDirection:"row",gap:"1.5rem"}}>

        <div className="download files-download">
          {
            token && <Link 
              to={`/upload/${metadata?.main_id}/${metadata?.child_id}`}
              onClick={(e) => {
                e.stopPropagation()
              }}
              className="mt-0"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path
                  fill="#000"
                  d="M14.293,2.293l-1.586-1.586a1,1,0,0,0-1.414,0L2.293,10.293a1,1,0,0,0-.293.707V15a1,1,0,0,0,1,1H5.586a1,1,0,0,0,.707-.293L14.293,3.707A1,1,0,0,0,14.293,2.293ZM4,13V11.414L11.414,4H13L4,13ZM12.293,2L14,3.707,12.293,2ZM3.586,12H5v1.414L3.586,12Z"
                />
              </svg>
            </Link>
          }
      
          <a href={metadata.url}>file</a>
          {metadata.activity_type ? <p className="activityType">{metadata.activity_type}</p> : null}
        </div>

        </div>
        {isButtonShow(metadata) ? 
        <button 
          className={`arrowDown ${!open ? "" : "arrowUp"}`}
          onClick={(event:any) => {
            event.stopPropagation();
            setOpen(!open)
          }}
        ></button>
        : null}
     
      </div>
      <div 
        style={{height: !open ? 0 : metadata.file_extension === "tcx" && metadata.maximum_heart_rate ? 22 : 42, overflow: "hidden", transition: "all ease 0.3s"}} 
        className={`elevator ${!isButtonShow(metadata) || !open ? "mt0 pt-0 pb-0" : ""} ${metadata.file_extension === "tcx" && metadata.maximum_heart_rate ? "pb-0" : ""}`}
      >
        {/* {
        metadata.file_extension === "gpx" ? 
        <>
          <label>Min: <span>{metadata.elevation?.min && metadata.elevation?.min ? metadata.elevation.min.toFixed(2) : "-"}</span></label>
          <label>Average: <span>{metadata.elevation.avg && metadata.elevation.avg ? metadata.elevation.avg.toFixed(2) : "-"}</span></label>
          <label>Max: <span>{metadata.elevation?.max && metadata.elevation?.max ? metadata.elevation.max.toFixed(2) : "-"}</span></label>
        </>
        : null} */}
        {
            metadata.file_extension === "tcx" && metadata.maximum_heart_rate ? 
            <>
              <label className="mb-1">Max Heart Rate: <b>{metadata.maximum_heart_rate.toFixed(2)} </b></label>
            </>
            : null
          }
        {
        metadata.file_extension === "fit" ? 
        <>
          <label>Max Heart Rate: <span>{metadata.max_heart_rate && metadata.max_heart_rate ? metadata.max_heart_rate.toFixed(2) : "-"}</span></label>
          <label>Avg Speed: <span>{metadata.avg_speed && metadata.avg_speed ? metadata.avg_speed.toFixed(2) : "-"}</span></label>
          <label>Avg Heart Rate: <span>{metadata.avg_heart_rate && metadata.avg_heart_rate ? metadata.avg_heart_rate.toFixed(2) : "-"}</span></label>
        </>
        : null}
        {
        metadata.file_extension === "hst" ? 
        <>
          <label>Max Heart Rate: <span>{metadata.max_heart_rate && metadata.max_heart_rate ? metadata.max_heart_rate.toFixed(2) : "-"}</span></label>
          <label>Max Speed: <span>{metadata.maximum_speed && metadata.maximum_speed ? metadata.maximum_speed.toFixed(2) : "-"}</span></label>
          <label>Avg Heart Rate: <span>{metadata.avg_heart_rate && metadata.avg_heart_rate ? metadata.avg_heart_rate.toFixed(2) : "-"}</span></label>
        </>
        : null}
      </div>
      
    </div>
    </>
  );
};

