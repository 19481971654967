/* eslint-disable react-hooks/exhaustive-deps */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { RootState } from 'store/store';
import { getElevation } from 'utils/CommonService';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  elements: {
    point:{
        radius: 0.000001
    }
  },
  plugins: {
    legend: {
      position: 'top' as const,
      display:false,
    },
    title: {
      display: true,
      text: '',
    },
  }
};



interface ChartPropsType {
  selectedFile: any
}

export default function Chart({selectedFile}: ChartPropsType) {
  const dispatch = useAppDispatch();
  const { files } = useAppSelector((state: RootState) => state.uploadfile);
  const fileData = files.find((e: any) => e.id === selectedFile);

  const handleHover = (event: any) => {
    // const heatMapPoints = fileData.graphPoints.filter((e: any) => typeof e[2] === "number" && e[2]);
    if (event && event.tooltip && event.tooltip.dataPoints && event.tooltip.dataPoints.length && event.tooltip.dataPoints[0].dataIndex) {
      const index = event.tooltip.dataPoints[0].dataIndex;
      // setInd(index)
      const location = heatMapPoints[index];
      // if(index !== ind) 
      dispatch({ type: "uploadfile/updatePointLocation", payload: location })
    } else {
      // setInd(null);
      dispatch({ type: "uploadfile/updatePointLocation", payload: null })
    }
  }

  const handleLeave = () => {
    // setInd(null);
    // dispatch({ type: "uploadfile/updatePointLocation", payload: null })
  }

  if(!fileData || !fileData.graphPoints.filter((e: any) => typeof e[2] === "number" && e[2]).length) {
    return (
    <div className='NoData'>
      <Line  style={{ maxHeight: "20rem" }} options={options} data={{
        labels: [],
        datasets: [
            {
                label: "Elevation",
                data: [],
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                tension: 0.3,
            },
        ],
      }} />
      <span>No Data</span>
    </div>
    )
  }

  const heatMapPoints = fileData.graphPoints.filter((e: any) => typeof e[2] === "number" && e[2]);
  
  const { distances, elevations, xAxisLabel, yAxisLabel } = getElevation(heatMapPoints);
  const label = distances.map((distance, index) => {
    if(index % (distances.length >= 10 ? Math.ceil(distances.length / 10) : 1) === 0 || ((distances.length-1) === index)) {
      return distance.toFixed(2);
    } else {
      return '';
    }
  });

  const data = {
    labels: label,
    datasets: [
        {
            label: "Elevation",
            data: elevations,
            fill: false,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
            tension: 0.3,
        },
    ],
  };
  
  return <div onMouseLeave={handleLeave}><Line data={data} style={{ maxHeight: "20rem" }}
  options={{
    ...options,
    plugins: {
      legend: {
        position: 'top',
        display: true
      },
      title: {
        display: true,
        text: '',
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        external: handleHover,
        animation: {
          duration: 0
        },
        animations: false,
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: function(_: number | string, indx: number) {
            if(indx % (distances.length >= 10 ? Math.ceil(distances.length / 10) : 1) === 0 || ((distances.length-1) === indx)) {
              const value = distances[indx];
              if(((distances.length-1) === indx) && fileData.total_distance_kilometre) return fileData.total_distance_kilometre.toFixed(2) + " " + xAxisLabel;
              return(+value).toFixed(2) + " " + xAxisLabel;
            } else {
              return null;
            }
          }
        }
      },
      y: {
        ticks: {
          callback: function(value: any) {
            return value + " " + yAxisLabel;
          }
        }
      }
    },
  }}  /></div>
}
