import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import Cookies from "js-cookie";

// const token = Cookies.get("token");
// const refresh_token = Cookies.get("refresh_token");
// const user = Cookies.get("user");

const initialState: any = {
  activeActivity: [],
  allActiveActivity:[]
};

export const ActivitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    addToActiveActivity: (state, action: PayloadAction<any>) => {
      state.activeActivity = [...action.payload];
    },
    addSelectedFile: (state, action: PayloadAction<any>) => {
      state.selectedFile = action.payload;
    },
    AddNewActivity: (state: any, action: PayloadAction<any>) => {
       
      state.activeActivity = [action.payload, ...state.activeActivity];
    },
    DeleteActivityAction: (state, action: PayloadAction<any>) => {
      state.activeActivity = state.activeActivity.filter(
        (tag: any) => tag.id !== action.payload
      );
    },
    UpdateActivityAction: (state, action: PayloadAction<any>) => {
      state.activeActivity[action.payload.index] = {
        ...state.activeActivity[action.payload.index],
        active:action.payload.status
      }
    },
    AddAllActiveActivity:(state, action:PayloadAction<any>) => {
      state.allActiveActivity=action.payload
    }
  },
});
export const {
  addSelectedFile,
  addToActiveActivity,
  AddNewActivity,
  DeleteActivityAction,
  UpdateActivityAction,
  AddAllActiveActivity
} = ActivitySlice.actions;

export default ActivitySlice.reducer;

export const getALLFetchedActiveActivity=(state:any)=>(
  state.filter((tag:any)=> +tag.active === 1)
)


 