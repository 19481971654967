import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function SkeltonFile() {
    return(< SkeletonTheme baseColor = "" > <Skeleton
        count={1}
        style={{ width: "100%", height: "4rem" }}
        baseColor="white"
      /> < Skeleton count = {
        1
    }
    style = {{ width: "100%", height: "4rem" }}baseColor = "white" /> <Skeleton
        count={1}
        style={{ width: "100%", height: "4rem" }}
        baseColor="white"
      /> < Skeleton count = {
        1
    }
    style = {{ width: "100%", height: "4rem" }}baseColor = "white" /> </SkeletonTheme>);
}
export default SkeltonFile;
