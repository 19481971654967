import { lazy } from "react";
import { ROUTES_PATH } from "../utils/constant";
import GoogleMap from "pages/Map";


const Login = lazy(() => import("pages/Login"));

const Home = lazy(() => import("pages/Dashboard"));

const UploadActivity = lazy(() => import("pages/UploadActivity"));

const TagPage = lazy(() => import("pages/AddTags"));
const ActivityPage= lazy(()=>import  ("pages/AddActivity"))
const EditActivity= lazy(()=> import ("pages/EditFile"))
export const publicRoutes = [
  {
    key: "login",
    path: ROUTES_PATH.LOGIN,
    component: Login,
    // layout: PublicLayout,
  },
];

export const privateRoutes = [
  {
    key: "home",
    path: ROUTES_PATH.DASHBOARD,
    component: Home,
    // layout: PrivateLayout,
  },
  {
    key: "upload",
    path: ROUTES_PATH.UPLOADACTIVITY,
    component: UploadActivity,
  },
  {
    key: "add-tags",
    path: ROUTES_PATH.ADDTAGS,
    component: TagPage,
  },
  {
    key: "add-activity",
    path: ROUTES_PATH.ADDactivity,
    component: ActivityPage,
  },
  {
    key: "edit-activity",
    path: `${ROUTES_PATH.UPLOADACTIVITY}/:id/:childId`,
    component: EditActivity,
  },

];

export const openPublicRoutes = [
  {
    key: "map",
    path: ROUTES_PATH.MAP,
    component: GoogleMap,
  },
];
