import { AppDispatch } from "../store";
import * as AuthAction from "../reducers/authReducer";
import { auth, db } from "firebase-setup/setup";
import { signInWithEmailAndPassword } from "firebase/auth";
import { forErrorToast } from "utils/CommonService";
import { doc, getDoc } from "firebase/firestore";
export const login = (formData: any) => async (dispatch: AppDispatch) => {
    try {
      const credentials: any = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      if (credentials) {
        //we need to use an api call by firebase 

        const userId=credentials.user.uid
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
        let user=null
        if (docSnap.exists()) {
          const data=docSnap.data()
          if(data){
            user=data
          }
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
        

        dispatch(
          AuthAction.login({
            id:credentials.user.uid,
            access_token: credentials._tokenResponse.idToken,
            refresh_token: credentials._tokenResponse.refreshToken,
            email:credentials.user.email,
            user:user
          })
        );
        return true;
      }
    } catch (error: any) {
      if (error) {
        if (error.code.includes("auth/wrong-password")) {
          forErrorToast("Password Is Wrong");
        } else if (error.code.includes("auth/user-not-found")) {
          forErrorToast("User not found");
        } else {
          forErrorToast("Something went wrong");
        }
      }
      return false
    }
};

export const logout = (dispatch: AppDispatch) => {
  dispatch(AuthAction.logout());
};

