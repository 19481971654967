import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import storage, { db } from "firebase-setup/setup";
import { forErrorToast, forSuccessToast } from "utils/CommonService";
import axios from "utils/axiosConfig";
import { v4 as uuidv4 } from 'uuid';
import {  collection, getDocs, query, where } from "firebase/firestore";
import { AppDispatch } from "store/store";
import { UpdateDateFiles } from "store/reducers/fileReducers";
export const uploadFile = async (file: any) => {
  try {
    // get File Here
    
    // const fileName = file.name;
    const typeArray = file.name.split(".")
  const type=typeArray[typeArray.length-1]
    const uniqueName = `${uuidv4()}.${type}`
    const path = `public/files/${type}/${uniqueName}`
    const storageRef = ref(storage, path)
    // Create file metadata including the content type

    // Upload the file and metadata

    const res = await uploadBytes(storageRef, file);
    if (res) {
      let url = await getDownloadURL(storageRef);
      if (url) {
        return {url, fileName: uniqueName, path};
      }
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const UploadToFirebase = async (filedata: any) => {
  try {
    
    const res: any = await axios.post(`/v1/activities`, filedata);
    
    if (res.status && res.code === 200) {
      forSuccessToast("File Uploaded Succesfully");
      return res?.body?.file_path?.split("/")[3];
    }
  } catch (error: any) {
    console.log(error);

    forErrorToast(error.response.errorMessages);
    return false;
  }
};

export const isTitleExist=async(title:string)=>{
  
  try {
    const Collection_ref=collection(db,"activities") 
    const q = query(Collection_ref, where("title", "==", title.trim()));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty
  } catch (error) {
    console.log(error);
    
  }
}

export const isFileExist=async(fileName:string)=>{
  
  const Collection_ref=collection(db,"activities") 
  const q = query(Collection_ref, where("file_original_name", "==", fileName) ,where("is_deleted","==",0));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty
}


export const GetActivity=async(id:string)=>{
    try {
      const res:any=await axios.get(`/v1/activity/${id}`)
      if(res.status){
        return res.body
      }
    } catch (error) {
      forErrorToast("Somethig Went Wrong!!!")
    }
}

export const updateActivity = async (id:string, body:any)=>{
    try {
       const res = await axios.put(`/v1/activity/${id}/child-activity`, body)
       if(res.status){
        return true
       }
    } catch (error) {
      forErrorToast("Something went wrong.")
    }
}

export const DeleteUploadActivity=async(id:string)=>{
  try {
    const res=await axios.delete(`/v1/activity/${id}`)
    if(res.status){
      return true
    }
  } catch (error) {
    return false
  }
}


export const DeleteUploadActivityTrack=async(id:string,child:string)=>{
  try {
    
    const res=await axios.delete(`/v1/activity/${id}/child?child_virtual_id=${child}`)
    
    if(res.status){
      return true
    }
  } catch (error) {
    return false
  }
}

const getTimeStamp = (dateTime: string) => { 
  const date = new Date(dateTime);
  const milliseconds = date.getTime();
  const _seconds = Math.floor(milliseconds / 1000);
  const remainingMilliseconds = milliseconds % 1000;
  const _nanoseconds = remainingMilliseconds * 1e6;
  return { _seconds, _nanoseconds }
}

export const UpdateCreatedDate=(body:any)=>async(dispatch:AppDispatch)=>{
   try {
      const {mainId,id,...actualBody}=body
      const timestampDate=getTimeStamp(body.file_created_at)
      
       const res=await axios.put(`/v1/activity/${body.mainId}/child`,actualBody)
       if(res.status){
         // we need to dispatch some action here based Upon Id 
          dispatch(UpdateDateFiles({id,file_created_at:timestampDate}))
          return true
       }
       
   } catch (error) {
    
   }
}



// .orderBy("file_original_name", "asc")
