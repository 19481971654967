/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import FilePage from "./files";
import GoogleMap from "./gmap";
import { Link } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import { Slider } from "@mui/material";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { ROUTES_PATH, SORTINGFILES, Sort_Type } from "utils/constant";
import { useDispatch } from "react-redux";

import { fetchActiveActivity, fetchActiveTags } from "store/actions/tagAction";
import { ThreeDots } from "react-loader-spinner";
import { forErrorToast } from "utils/CommonService";
import { getALLFetchedActiveTags } from "store/reducers/tagReducer";
import {
  AddFilterFiles,
  addSelectedFile,
  addWholeMarker,
} from "store/reducers/fileReducers";
import Chart from "pages/chart";
import Dropdown from "react-bootstrap/Dropdown";
import Multiselect from "multiselect-react-dropdown";

// import { LoadScript } from "@react-google-maps/api";

export default function MapPage() {
  const multiselectRef: any = useRef(null);
  const activeTags = useAppSelector((state: any) =>
    getALLFetchedActiveTags(state.tags.activeTags)
  );

  // const allActiveActivity = useAppSelector(
  //   (state) => state.activity.activeActivity
  // );
  
  const { uploadedGPXFiles, filteredFiles, SelectedFile }: any = useAppSelector(
    (state: RootState) => state.uploadfile
  );

  
  
  const { activeActivity }: any = useAppSelector(
    (state: RootState) => state.activity
  );

  const { token } = useAppSelector((state: RootState) => state.auth);
  const [filterState] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState<string>("");
  //cluster,polyline,heatmap
  const [ClusterToggle, setClusterToggle] = useState("cluster");

  const [clusterLatLng, setClusterLatLng] = useState<any>(null);
  const [clusterMarkerIds, setClusterMarkerIds] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState(false);

  // const selectesActivityRef=useRef<any>([])
  const defaultFilterParams: any = {
    dateduration: [],
    // distanceDuration: [+distance.min.toFixed(0), +distance.max.toFixed(0)],
    // traveltimeduration: [+duration.min.toFixed(0), +duration.max.toFixed(0)],
    distanceDuration: [0, 1000],
    traveltimeduration: [0, 1000],
    tag: [],
    activityType: "",
    creaed_at: "",
    createdDate: [],
  };

  const [filterData, setFilterData] = useState(defaultFilterParams);
  const [sortFile, setSortFile] = useState(SORTINGFILES.UPLOADED_DATE);
  const [SortType, setSortType] = useState(Sort_Type.DESC)
  // useEffect(() => {
  //   setFilterData({
  //     ...filterData,
  //     distanceDuration: [+distance.min.toFixed(0), +distance.max.toFixed(0)],
  //     traveltimeduration: [+duration.min.toFixed(0), +duration.max.toFixed(0)],
  //   });
  // }, [distance, duration]);

  const dispatch: any = useDispatch();

  const handleFilterChange = (e: any) => {
    const field = e.target.name;
    
    setFilterData({ ...filterData, [field]: e.target.value });
  };

  useEffect(() => {
    if (!activeTags.length) {
      dispatch(fetchActiveTags());
    }
    if (!activeActivity.length) {
      dispatch(fetchActiveActivity());
    }
  }, []);

  useEffect(()=>{
    if(SelectedFile?.id){
      const selectedElement:any= document.getElementById(SelectedFile.id);
      if(selectedElement) selectedElement.scrollIntoView({block:"nearest",behavior:"smooth"});
    }
  },[SelectedFile])

  const handleRangeField = (date: any) => {
    // setFilterData({ ...filterData, creaed_from: e[0], created_to: e[1] });
    if (date == null) {
      setFilterData({
        ...filterData,
        dateduration: defaultFilterParams.dateduration,
      });
    } else {
      if (date[0].toDateString() === date[1].toDateString()) {
        date[0].setDate(date[0].getDate() - 2);
      }
      setFilterData({
        ...filterData,
        dateduration: date ? [date[0], date[1]] : [],
      });
    }
  };

  const handleFilterdData: any = () => {
    if (Object.values(filterData).every((value) => value === "")) {
      return forErrorToast("Select Something Before Filter");
    } else {
      const tags = filterData.tag.map((item: any) => item.name);

      const filteredData = uploadedGPXFiles?.filter((item: any) => {
        
        // let createdAt:any = item?.uploaded_at? new Date(item?.uploaded_at) : "";
        // createdAt = createdAt ? createdAt.setHours(0, 0, 0, 0) : ""
        let createdAt:any = item?.file_created_at ? new Date(item?.file_created_at._seconds * 1000) : "";
        createdAt = createdAt ? createdAt.setHours(0, 0, 0, 0) : "";

        const startDate = filterData?.dateduration[0] !== undefined ? filterData?.dateduration[0].setHours(0,0,0,0) :null
        const endDate = filterData?.dateduration[1] !== undefined ? filterData?.dateduration[1].setHours(0,0,0,0) : null
        const createdAtInRange = (!startDate || createdAt >= startDate) && (!endDate || createdAt <= endDate);

        const isUploadDataInRang = filterData?.dateduration.length ? createdAtInRange : true;

        const isTag = filterData?.tag.length ? item?.tags.some((e: any) => tags.includes(e)) : true;
        const isActivity = filterData?.activityType ? item?.activity_type.includes(filterData?.activityType) : true;

        const isDistanceRanger =
          filterData.distanceDuration[0] !==
            defaultFilterParams.distanceDuration[0] ||
          filterData.distanceDuration[1] !==
            defaultFilterParams.distanceDuration[1]
            ? filterData.distanceDuration[0] <= item?.distance &&
              filterData.distanceDuration[1] >= item?.distance
            : true;

        const istimeRanger =
          filterData.traveltimeduration[0] !==
            defaultFilterParams.traveltimeduration[0] ||
          filterData.traveltimeduration[1] !==
            defaultFilterParams.traveltimeduration[1]
            ? filterData.traveltimeduration[0] <=
                item?.total_duration_minutes &&
              filterData.traveltimeduration[1] >= item?.total_duration_minutes
            : true;

        return (
          isTag &&
          isActivity &&
          isUploadDataInRang &&
          isDistanceRanger &&
          istimeRanger
        );
      });
      dispatch(AddFilterFiles(filteredData));
    }
  };
  
  const handleClearFilter = () => {
    setFilterData(defaultFilterParams);
    dispatch(AddFilterFiles(uploadedGPXFiles));
    multiselectRef.current.resetSelectedValues();
  };

  const distanceHandler = (e: any, newValue: any) => {
    setFilterData({
      ...filterData,
      distanceDuration: newValue,
    });
  };

  const onSelect = (e: any) => {
    setFilterData({ ...filterData, tag: e });
    const input: any = document.getElementsByName("search_name_input");
    
    if (e.length === activeActivity.length) {
      input[0].placeholder = "";
    } else {
      
      input[0].placeholder = "Select";
    }
  };

  const onRemove = (e: any) => {
    setFilterData({ ...filterData, tag: e });
    const input: any = document.getElementsByName("search_name_input");
    
    if(input[0].placeholder === ""){
      input[0].placeholder="Select"
    }
  };

  const disabledDate = (date: any) => {
    return date > new Date();
  };

  return (
    <>
      <div className="tool-container">
        <div className="tools fillter-wrapper">
          <div className="filter-left">
            <div className="tool">
              <label htmlFor="">Tags</label>
              <Multiselect
                options={
                  activeTags.length
                    ? activeTags.map((tag: any) => ({
                        name: tag.name,
                        id: tag.id,
                      }))
                    : []
                }
                selectedValues={filterData.tag} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                loading={activeTags.length ? false : true}
                ref={multiselectRef}
                className="multi-select"
              />
            </div>
            <div className="tool">
              <label htmlFor="activity">Activity Type</label>
              <select
                name="activityType"
                id="activity"
                className="input_modify select_modify"
                onChange={handleFilterChange}
                value={filterData.activityType}
              >
                {!filterData.activityType && <option value="">Choose Activity</option>}
                {activeActivity.length ? activeActivity.map((activity: any) => (
                      <option key={activity.name} value={activity?.name}>
                        {activity?.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="tool rangeDateTool">
              <label htmlFor="">Created date</label>
              <div className="dateRang">
                <DateRangePicker
                  format="MM-dd-yyyy"
                  placeholder="MM-DD-YYYY ~ MM-DD-YYYY"
                  value={filterData?.dateduration}
                  onChange={handleRangeField}
                  // onSelect={(e)=>{
                  //    
                  // }}
                  onClean={() =>
                    setFilterData({ ...filterData, dateduration: [] })
                  }
                  disabledDate={disabledDate}
                />
              </div>
            </div>

            {/* date Picker */}

            <div className="tool">
              <div className="col-md-12">
                <label htmlFor="">Distance Range (Km)</label>
                <Slider
                  getAriaLabel={() => "Distance range"}
                  value={filterData.distanceDuration}
                  onChange={distanceHandler}
                  valueLabelDisplay="auto"
                  min={0}
                  max={1000}
                  // min={+distance?.min.toFixed(0)}
                  // max={+distance?.max.toFixed(0)}
                />
                <div className="range-value-wrapper">
                  <p>
                    min:{" "}
                    <span>
                      {filterData?.distanceDuration?.length
                        ? filterData.distanceDuration[0]
                        : "-"}
                    </span>
                  </p>
                  <p>
                    max:{" "}
                    <span>
                      {filterData?.distanceDuration?.length
                        ? filterData.distanceDuration[1]
                        : "-"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="tool">
              <div className="col-md-12">
                <label htmlFor="">Duration range (Min.)</label>
                <Slider
                  getAriaLabel={() => "Travel Time range"}
                  value={filterData.traveltimeduration}
                  onChange={(e: any, newValue: any) => {
                    setFilterData({
                      ...filterData,
                      traveltimeduration: newValue,
                    });
                  }}
                  valueLabelDisplay="auto"
                  min={0}
                  max={1000}
                  // min={+duration?.min.toFixed(2)}
                  // max={+duration?.max.toFixed(2)}
                />
                <div className="range-value-wrapper">
                  <p>
                    min:{" "}
                    <span>
                      {filterData.traveltimeduration?.length
                        ? filterData?.traveltimeduration[0]
                        : "-"}
                    </span>
                  </p>
                  <p>
                    max:{" "}
                    <span>
                      {filterData.traveltimeduration?.length
                        ? filterData?.traveltimeduration[1]
                        : "-"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-right">
            <button
              className="filter-btn btn-dark btn mt-3"
              onClick={handleFilterdData}
              disabled={filterState}
            >
              {filterState ? (
                <ThreeDots
                  height="25"
                  width="45"
                  radius="9"
                  color="#FFFFFF"
                  ariaLabel="three-dots-loading"
                  visible={true}
                  wrapperStyle={{ justifyContent: "center" }}
                />
              ) : (
                "Apply Filter"
              )}
            </button>
            <button
              className="filter-btn btn-light btn mt-3"
              onClick={handleClearFilter}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
      <div className="main-content">
        
        <div className="map-main-wrapper map-main-wrapper-new">
          <div className="file-list-wrapper">
            <div className="dropdown-link-wrapper">
            <div className="dropdown-wrapper">
            <button className={`text-sort ${SortType === Sort_Type.DESC ? "" : "uparrow"}`} onClick={() => setSortType((prev)=> prev === Sort_Type.ASC ? Sort_Type.DESC : Sort_Type.ASC)}>Sort By
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.809"
                  height="6.182"
                  viewBox="0 0 10.809 6.182"
                >
                  <path
                    id="Icon_ionic-ios-arrow-down"
                    data-name="Icon ionic-ios-arrow-down"
                    d="M11.594,15.565l4.087-4.091a.769.769,0,0,1,1.091,0,.779.779,0,0,1,0,1.094L12.141,17.2a.771.771,0,0,1-1.065.023L6.413,12.571A.773.773,0,1,1,7.5,11.477Z"
                    transform="translate(-6.188 -11.246)"
                    fill="#011d22"
                  />
                </svg>
            </button>
            <Dropdown className="sortDropDown">
              <Dropdown.Toggle id="dropdown-basic">
                {sortFile === SORTINGFILES.UPLOADED_DATE
                  ? "Uploaded date"
                  : "Created date"}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.809"
                  height="6.182"
                  viewBox="0 0 10.809 6.182"
                >
                  <path
                    id="Icon_ionic-ios-arrow-down"
                    data-name="Icon ionic-ios-arrow-down"
                    d="M11.594,15.565l4.087-4.091a.769.769,0,0,1,1.091,0,.779.779,0,0,1,0,1.094L12.141,17.2a.771.771,0,0,1-1.065.023L6.413,12.571A.773.773,0,1,1,7.5,11.477Z"
                    transform="translate(-6.188 -11.246)"
                    fill="#011d22"
                  />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  // value={SORTINGFILES.UPLOADED_DATE}
                  onClick={() => {
                    setSortFile(SORTINGFILES.UPLOADED_DATE);
                  }}
                >
                  Uploaded date
                </Dropdown.Item>

                <Dropdown.Item
                  // value={SORTINGFILES.CREATED_DATE}
                  onClick={() => {
                    setSortFile(SORTINGFILES.CREATED_DATE);
                  }}
                >
                  Created date
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
            </div>
          </div>
          <div className="map-wrapper">
            <div className="dropdown-link-wrapper">
                <div className="dropdown-wrapper">
                  <div className="view-btn-wrapper">
                    <button className={ClusterToggle === "polyline" ? "active" :"" } onClick={(e:any)=>{
                      e.preventDefault()
                      setClusterToggle("polyline")
                    }}>  HeatMap</button>
                    <button className={ClusterToggle === "cluster" ? "active" :"" }  onClick={(e:any)=>{
                      e.preventDefault()
                      setClusterToggle("cluster")
                    }}> Cluster</button>
                    {/* <button className={ ClusterToggle === "heatmap" ? "active" :"" }  onClick={(e:any)=>{
                      e.preventDefault()
                      setClusterToggle("heatmap")
                    }}> google heatmap
                    </button> */}
                  </div>
                </div>
                {token ? (
                  <Link
                    to={ROUTES_PATH.UPLOADACTIVITY}
                    className="link-btn"
                    onClick={() => {
                      dispatch(addSelectedFile([]));
                      dispatch(addWholeMarker([]));
                    }}
                  >
                    Add Activity
                  </Link>
                ) : null}
              </div>
          </div>
        </div>
        <div className="map-main-wrapper">
          <div className="file-list-wrapper">
            <FilePage
              filteredFiles={filteredFiles}
              loading={loading}
              setLoading={setLoading}
              setSelectedFile={setSelectedFile}
              sortBy={sortFile}
              SelectedFile={SelectedFile}
              SortType={SortType}
              clusterLatLng={clusterLatLng}
              setClusterLatLng={setClusterLatLng}
              clusterMarkerIds={clusterMarkerIds}
              setClusterMarkerIds={setClusterMarkerIds}
              showPopup={showPopup}
              setShowPopup={setShowPopup}
            />
          </div>
          <div className="map-wrapper">
              <GoogleMap 
                setLoading={setLoading} 
                selectedFile={selectedFile} 
                setSelectedFile={setSelectedFile}
                clusterShow={ClusterToggle}
                clusterLatLng={clusterLatLng}
                setClusterLatLng={setClusterLatLng}
                clusterMarkerIds={clusterMarkerIds}
                setClusterMarkerIds={setClusterMarkerIds}
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                sortFile={sortFile}
                SortType={SortType}
              />
          </div>
        </div>
        <Chart selectedFile={selectedFile} />
      </div>
    </>
  );
}

//this MapPage Need to show The Files and Map
