import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import Cookies from "js-cookie";
// import { AuthState, LoginRes, SignupRes } from "../types/authType";

// const token = Cookies.get("token");
// const refresh_token = Cookies.get("refresh_token");
// const user = Cookies.get("user");

const initialState: any = {
  uploadedGPXFiles: [],
  SelectedFile: null,
  filteredFiles: [],
  addedGpxFiles:[],
  distance:{
    max:1000,
    min:0,
  },
  duration:{
    max:1000,
    min:0,
  },
  files: [],
  whole_MarkersDetails: {whole_Markers:[],center:{}},
  pointLocation: null,
  clusterKey: 0
};

export const UploadFileSlice = createSlice({
  name: "uploadfile",
  initialState,
  reducers: {
    addToUploadFiles: (state, action: PayloadAction<any>) => {
      state.uploadedGPXFiles = action.payload;
      state.filteredFiles = action.payload;
    },
    addSelectedFile: (state, action: PayloadAction<any>) => {
      if(!action.payload){
        state.selectedFile = null;
        return
      }
      const metadata = state.files.find((e: any) => e.id === action.payload._id);
      if(metadata) state.selectedFile = metadata;
      else state.selectedFile = null;
    },
    AddFilterFiles: (state, action: PayloadAction<any>) => {
      state.filteredFiles = action.payload;
      state.clusterKey = state.clusterKey + 1
    },
    addSelectFile:(state,action:PayloadAction<any>)=>{
      if(!action.payload){
        state.SelectedFile = null;
      } else {
        state.SelectedFile = action.payload
      }
    },
    addTodistanceAndDuration: (state, action: PayloadAction<any>) => {
      state.distance.max = action.payload.distance.max
      state.distance.min = action.payload.distance.min
      state.duration.max = action.payload.duration.max
      state.duration.min = action.payload.duration.min
    },
    updateFiles: (state, action: PayloadAction<any>) => {
      state.files.push(action.payload);
      // state.files.sort((a: any, b: any) => a.number - b.number)
    },
    updateBatchFiles: (state, action: PayloadAction<any>) => {
      state.files = [...state.files, ...action.payload];
      // state.files.sort((a: any, b: any) => a.number - b.number)
    },
    addWholeMarker:(state, action:PayloadAction<any>)=>{
      state.whole_MarkersDetails=action.payload
    },
    clearAllMapData: (state)=>{
      state.uploadedGPXFiles = [];
      state.SelectedFile = null;
      state.filteredFiles = [];
      state.addedGpxFiles = [];
      state.files = [];
      state.whole_MarkersDetails = { whole_Markers: [], center: {}};
    },
    updatePointLocation: (state, action: PayloadAction<any>) => {
      state.pointLocation = action.payload;
    },
    DeleteFiles:(state,action:PayloadAction<any>)=>{
      if(action.payload.child_id!==null){ //child_id
        
        let index=state.filteredFiles.findIndex((item:any)=>item.main_id === action.payload.main_id && item.child_id === action.payload.child_id)
        let uniqueId= state.filteredFiles[index]._id
        
         state.files=state.files.filter((item:any)=> item.id !== uniqueId)
         state.uploadedGPXFiles=state.uploadedGPXFiles.filter((item:any,i:number)=> item._id !== uniqueId)
        state.filteredFiles=state.filteredFiles.filter((item:any,i:number)=> item._id !== uniqueId )
      
        //state.uploadedGPXFiles=state.uploadedGPXFiles.filter((item:any)=>item.main_id !== action.payload.main_id && item.child_id !==action.payload.child_id)
        //state.filteredFiles=state.filteredFiles.filter((item:any)=>item.main_id !== action.payload.main_id && item.child_id !==action.payload.child_id)
      }else{
        state.filteredFiles=state.filteredFiles.filter((item:any)=> item.main_id !== action.payload.main_id )
      }
    },
    UpdateDateFiles:(state,action:PayloadAction<any>)=>{
      if(action.payload.id && action.payload.file_created_at){
       
        let findex=state.filteredFiles.findIndex((item:any)=>item._id === action.payload.id )
        state.filteredFiles[findex]={...state.filteredFiles[findex],file_created_at:action.payload.file_created_at}
        
        let uindex=state.uploadedGPXFiles.findIndex((item:any)=>item._id === action.payload.id )
        state.uploadedGPXFiles[uindex]={...state.uploadedGPXFiles[uindex],file_created_at:action.payload.file_created_at}
      }
    }
  },
});
export const { addSelectedFile, addToUploadFiles, AddFilterFiles, addTodistanceAndDuration, updateFiles, addWholeMarker, addSelectFile, clearAllMapData, updatePointLocation, DeleteFiles, UpdateDateFiles, updateBatchFiles } = UploadFileSlice.actions;

export default UploadFileSlice.reducer;
