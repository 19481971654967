import { ToastOptions } from "react-toastify";

export const PUBLIC_PATH = {
  LOGIN: "/login",
};

export const PRIVATE_PATH = {
  DASHBOARD: "/dashboard",
  UPLOADACTIVITY:"/upload",
  MAP:"/",
  ADDTAGS:"add-tags",
  ADDactivity:"add-activity",
};

export const ROUTES_PATH = {
  ...PUBLIC_PATH,
  ...PRIVATE_PATH
};

export const pagePerOptions = [5, 10, 25];
export const limit = 10;

export const helpcontent = {
  welcome: "welcome",
  pramenu: "pramenu",
  definition: "definition",
  workarea: "workarea",
  individual: "individual",
  newreserveitem: "newreserveitem",
  newsite: "newsite",
  newproperty: "newproperty",
  editproperty: "editproperty",
  warningsms: "warningsms",
  reserveprofile: "reserveprofile",
  systemmethods: "systemmethods"
}


export const tableData1 = [
  "Custom Report 1", 
  "Custom Report 2",
  "Sites",
  "Properties",
  "Phases Units Model",
  "Unit Address",
  "Model Allocation",
  "Definition",
  "Item Summary",
  "Item Details",
  "Individual Unit",
  "Expenditure Summary",
  "Expenditure Detail",
  "Expenditure History",
]

export  const tableData2 = [
  "Saved Header",
  "Property Manager Sites",
  "Cash Manager Analysis Summary",
  "Saved Footer",
  "Property View",
  "Model Allocations"
] 


export const minimumUploads=5

export const toastOptions:ToastOptions={
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

export const CollectionNames={
  TAGS:"tags",
  ACTIVITY:"activity_type"
}

export const ACTIVESTATUS={
  active:1,
  disable:0,
}

export const DELETEDSTATUS={
  deleted:1,
  notDeleted:0
}

export const TAGSFIELDS={
  ACTIVE:"active",
  CREATED_AT:"created_at",
  ISDELETED:"is_deleted"

}

export const SORTINGFILES={
  UPLOADED_DATE: "created_at",
  CREATED_DATE: "file_created_at"
}

export const clusterStyles = [
  {
      textColor: 'white',
      url: "https://firebasestorage.googleapis.com/v0/b/savuti-c0969.appspot.com/o/Africa%20Toes%20Original.png?alt=media&token=e843b6bc-b88e-4802-9d96-e3b9d527c6ba",
      height: 30,
      width: 30
  }
];

 export const BOUNDS = {
    north: 85,
    south: -85,
    west: -180,
    east: 180,
  };

export const GmapStyle={
  style:[ { 
    "featureType": "water", 
    "stylers": [ { "color": "#d2d5d5" } ] 
  },
  { 
    "featureType": "landscape", 
    "elementType": "geometry", 
    "stylers": [ { "color": "#eff0f0" } ]
  },
  
  { 
  "featureType": "road", 
  "elementType": "geometry", 
  "stylers": [ { "visibility": "on", "color": "#ffffff" } ] 
  },
  
  { 
  "featureType": "road", 
  "elementType": "geometry.stroke", 
  "stylers": [ { 
  "color": "#ffffff" }, { 
  "weight": 0 }, { 
  "visibility": "on" } 
  ]},
  
  { 
  "featureType": "administrative.locality", 
  "stylers": [ { "visibility": "off" } 
  ]
  },
  { 
  "featureType": "administrative.country", 
  "elementType": "labels.text",
  "stylers": [ { "color": "#9a9f9f" } ]
  },
  {
    "featureType": 'administrative.country',
    "elementType": 'labels.text.stroke',
    "stylers": [{color: '#ffffff'}, {weight: 2}]
  }
  
  ]
}

export const Sort_Type={
  ASC:"ascending",
  DESC:"descending"
}