import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type LayoutState = {
    isSideBar: boolean;
    commonLoader: boolean;
}

const initialState: LayoutState = {
    isSideBar: false,
    commonLoader: false
};

export const layoutSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleSideBar: (state, action: PayloadAction<LayoutState>) => {
      state.isSideBar = action.payload.isSideBar;
    },
    updateCommonLoader: (state, action: PayloadAction<boolean>) => {
      state.commonLoader = action.payload
    }
  },
});
export const { toggleSideBar, updateCommonLoader } = layoutSlice.actions;

export default layoutSlice.reducer;
