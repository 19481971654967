import * as toastr from 'toastr'
import { BehaviorSubject } from "rx";
import { toast } from "react-toastify";
import { toastOptions } from './constant';
import JSZip from 'jszip';
import { calculateDistance } from 'store/actions/mapActions';
import moment from 'moment';
import * as turf from '@turf/turf';


// for global loader service
export const isLoading = new BehaviorSubject<boolean>(false);

export const isDialogOpen = new BehaviorSubject<any>({
  open: false,
  data: { message: "Are you Sure?", title: "" },
  cancelText: "Cancel",
  confirmText: "Okay",
  onConfirm: () => {},
});

export const forSuccess = (message: string) =>
  toastr.success(message, "Success");

export const forError = (message: string) => toastr.error(message, "Error");
export const forErrorToast=(message:string)=>toast.error(message,toastOptions)
export const forWarningToast= (message:string) => toast.warning(message,toastOptions)
export const forSuccessToast= (message:string) => toast.success(message,toastOptions)
export const forWarning = (message: string) =>
  toastr.warning(message, "Warning");

export const debounce=(fn:any,delay:number)=>{
  return function(...args:any){
    let timer;
    if(timer) clearTimeout(timer)
     timer=setTimeout(fn(),delay)
  }
}

let db=false;
export const debounceClickMap=(fn:any,delay:number)=>{
  return function(...args:any){
    let timer;
    console.log(db);
    
    if(db){

      return 
    }
    db=true
    if(timer) clearTimeout(timer)
     timer=setTimeout(fn,delay)

  }
}


export function getCordinates(feature:any){
  if(feature.geometry.type==="GeometryCollection"){
      //its have arrays of geometry
      const geometries=feature.geometry.geometries
      const  newCordinates=geometries.map((e:any)=>{
       return convertToAppropriateArrayFormat(
         e.coordinates
       );
       
      })

      return newCordinates.flat()

  }
  if(feature.geometry.type==="Point"){
    return []
  }
  return convertToAppropriateArrayFormat(feature.geometry.coordinates)
 
}


const convertToAppropriateArrayFormat = (array: any) => {
  while (typeof array[0] === "number" || Array.isArray(array[0][0])) {
    if (Array.isArray(array[0][0])) {
      array = array.flat();
    } else {
      array = [[array]];
    }
  }

  return array;
};


export async function ConvertToKMLfromKMZ(kmzFile:any){
   try {
    const zip = new JSZip();
  
  
     const bufferedKMZ=await kmzFile.arrayBuffer()
     const kmz = await zip.loadAsync(bufferedKMZ);
     
     
     const f_name=Object.keys(kmz.files)[0]
     const kmlContent = await kmz.file(f_name).async('text');
     return kmlContent;
     
   } catch (error) {
    console.log("error kmz",error);
    
   }
}


export function GetClosestCenter(this:any,selectedFile:any){
 const clusterCenter=this.clusters_.map((cls:any)=> cls.center_)
 let centerPosition=clusterCenter.reduce((prev:any, center:any) => {
   // Calculate the absolute difference between the current element and the target
   let distance=calculateDistance([center.lng(),center.lat()], [selectedFile?.heatMapPoints[0][0],selectedFile?.heatMapPoints[0][1]])
   return prev.distance !== 0 ?  distance < prev.distance ? {distance:distance,center:center} : prev : {distance:distance,center:center}
 },{distance:0,center:null});

 return {lat:centerPosition.center.lat(),lng:centerPosition.center.lng()}
 
}


export const setAllPolyline = (gMap: any, activities: any) => {
  
  
  for (let i = 0; i < activities.length; i++) {
    setSinglePolyline(gMap, activities[i])
  }

    // let startLatLng = new window.google.maps.LatLng(path[i].position.lat, path[i].position.lng);
    // let endLatLng = new window.google.maps.LatLng(path[i].heatPoints[1][1], path[i].heatPoints[1][0]);
    // const activities = [...path].filter((_: any, indx: number) => indx !== i);
    // // let color;
    // const isMoreActivity = countNearbyActivities(startLatLng, endLatLng, activities) >= DENSITY_THRESHOLD
    // console.log(isMoreActivity, "isMoreActivity")
    // if () {
    //     color = "#FFD700"; // Golden
    // } else {
    //     color = "#FF0000"; // Red
    // }
    // const route = path[i].heatPoints.map((e: any) => new window.google.maps.LatLng(e[1], e[0]));
    // console.log(route.length)

    // let segment = new window.google.maps.Polyline({
    //     path: route,
    //     geodesic: true,
    //     // strokeColor: isMoreActivity ? "#ff0000" : "#4e002b",
    //     strokeOpacity: 1.0,
    //     strokeWeight: 3,
    //     map: gMap
    // });
    // polylines.push(segment);
  }
// }

const getNearbyActivities = (start: any, end: any, activities: any) => {
  const DENSITY_RADIUS = 10; // 10 meters, adjust as needed
  let count = 1;
  for (let activity of activities) {
    let activityPoint = new google.maps.LatLng(activity.points[0].lat, activity.points[0].lon);
    let distanceToStart = google.maps.geometry.spherical.computeDistanceBetween(activityPoint, start);
    let distanceToEnd = google.maps.geometry.spherical.computeDistanceBetween(activityPoint, end);
    if (distanceToStart < DENSITY_RADIUS || distanceToEnd < DENSITY_RADIUS) {
      count++;
    }
  }
  return count;
}

export const setPathColor = (activities: any) => {
  const getNumber = activities.map((e: any, i: number) => {
      const activity = activities[i];
      const startLatLng = new google.maps.LatLng(activity.points[0].lat, activity.points[0].lon);
      const endLatLng = new google.maps.LatLng(activity.points[1].lat, activity.points[1].lon);
      const count = getNearbyActivities(startLatLng, endLatLng, activities.filter((_: any, ind: number) => ind !== i));
      return {
        ...e,
        number: count
      }
    })
  const maxValue = getNumber.length ? Math.max(...getNumber.map((e: any) => e.number)) : 0;
  const minValue = getNumber.length ? Math.min(...getNumber.map((e: any) => e.number)) : 0;
  const finalActivities = getNumber.map((e: any) => ({ ...e, maxValue: maxValue ? maxValue : 1, minValue: minValue ? minValue : 0, fraction: getFractionValue(maxValue, e.number), strockRang: e.number === minValue ? 0 : (e.number / maxValue)*100 }))
  
  return finalActivities
}

const getFractionValue = (maxValue: number, number: number) => {  
  return  +((number-1) / (maxValue-1)).toFixed(4)
}


export const setPathColorNRemoveDuplicate = (activities: any) => {
  const DENSITY_RADIUS = 100 // meter
  const allActivity = [...activities];
  const uniqArray = [];
  const duplicatIds: string[] = []
  
  for(let i=0; allActivity.length > i; i++) {
    const activity = allActivity[i];
    if(!duplicatIds.includes(activity._id)) {
      const start = activity.points[0];
      const end = activity.points[1];
      
      const googleStart = new google.maps.LatLng(start.lat, start.lon);
      const googleEnd = new google.maps.LatLng(end.lat, end.lon);
      const otherActivities = activities.filter((e: any) => e._id !== activity._id);
      let count = 0;
      for (let act of otherActivities) {
        const actStartPoint = act.points[0];
        const actEndPoint = act.points[1];

        const googleStartPoint = new google.maps.LatLng(actStartPoint.lat, actStartPoint.lon);
        const googleEndPoint = new google.maps.LatLng(actEndPoint.lat, actEndPoint.lon);

        const distanceToStart = google.maps.geometry.spherical.computeDistanceBetween(googleStartPoint, googleStart);
        const distanceToEnd = google.maps.geometry.spherical.computeDistanceBetween(googleEndPoint, googleEnd);
        
        if (distanceToStart < DENSITY_RADIUS && distanceToEnd < DENSITY_RADIUS && Math.abs(act?.total_distance_kilometre - activity?.total_distance_kilometre) <= 10) {
          duplicatIds.push(act._id);
          count++
        }
      }
      uniqArray.push({...activity, number: count})
    }
  }
  const maxValue = uniqArray.length ? Math.max(...uniqArray.map((e: any) => e.number)) : 0;
  const minValue = uniqArray.length ? Math.min(...uniqArray.map((e: any) => e.number)) : 0;
  const finalActivities = uniqArray.map((e: any) => ({ ...e, maxValue: maxValue ? maxValue : 1, minValue: minValue ? minValue : 0 }))
  return finalActivities
}

let polylines: any = [];

export const setSinglePolyline = (map: any, activity: any) => {
  const route = activity.heatMapPoints.map((e: any) => new window.google.maps.LatLng(e[1], e[0]));

  // const color = generateColor(activity.fraction)

  const colorRang = activity.maxValue / 4;
  const colorRangTwoTimes = colorRang * 2;
  const colorRangThreeTimes = colorRang * 3;
  let strokeWeight = 2.3;
  let color = "#dc2f02"
  let zIndex = 3

  if(activity.number >= colorRang && activity.number < colorRangTwoTimes) {
    strokeWeight = 1.5;
    color = "#87000f"
    zIndex = 2
  }
  if(activity.number >= colorRangTwoTimes && activity.number < colorRangThreeTimes) {
    strokeWeight = 1.5;
    color = "#87000f"
    zIndex = 2
  }
  if(activity.number >= colorRangThreeTimes && activity.number < activity.maxValue) {
    strokeWeight = 0.5;
    color = "#873938"
    zIndex = 1
  }
  let polyline = new window.google.maps.Polyline({
      path: route,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: strokeWeight,
      map: map,
      zIndex
  });
  polylines.push(polyline);
}

export function clearAllPolylines() {
  for (let i = 0; i < polylines.length; i++) {
      polylines[i].setMap(null);
  }
  polylines = [];
}




export function douglasPeucker(points: any, epsilon: number) {
  if (!points || points.length <= 2) return points;

  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];
  let index = -1;
  let maxDist = 0;

  for (let i = 1; i < points.length - 1; i++) {
      const dist = perpendicularDistance(points[i], firstPoint, lastPoint);
      if (dist > maxDist) {
          index = i;
          maxDist = dist;
      }
  }

  if (maxDist >= epsilon) {
      const leftRecursion: any = douglasPeucker(points.slice(0, index + 1), epsilon);
      const rightRecursion: any = douglasPeucker(points.slice(index), epsilon);
      
      return leftRecursion.slice(0, leftRecursion.length - 1).concat(rightRecursion);
  } else {
      return [firstPoint, lastPoint];
  }
}

function perpendicularDistance(point: number[], lineStart:number[], lineEnd:number[]) {
  const dx = lineEnd[1] - lineStart[1];
  const dy = lineEnd[0] - lineStart[0];

  const numerator = Math.abs(dy * point[1] - dx * point[0] + lineEnd[1] * lineStart[0] - lineEnd[0] * lineStart[1]);
  const denominator = Math.sqrt(dx * dx + dy * dy);

  return numerator / denominator;
}

export const debounceT =  (func:any) => {
  let timer:any=null
  return function (this:any,...args:any) {
    const context:any = this
    if(timer) clearTimeout(timer)
    timer = setTimeout(() => {
        timer = null;
        func.apply(context, args)
    },1500);
  }
}


function calculateLatLngDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
  const R = 6371; // Radius of the earth in km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  return R * c;
}

export const getElevation = (coordinates: number[][]) => {
  // Adjust your data extraction step to also compute cumulative distances
  const coords = coordinates;
  let elevations = [];
  let distances = [];
  let cumulativeDistance = 0;

  for (let i = 0; i < coords.length; i++) {
      if (i > 0) {
          const [lon1, lat1] = coords[i-1];
          const [lon2, lat2] = coords[i];
          cumulativeDistance += calculateLatLngDistance(lat1, lon1, lat2, lon2);
      }
      distances.push(cumulativeDistance);
      elevations.push(coords[i][2]);
  }

  // Decide on unit conversions
  const totalDistance = distances[distances.length - 1];
  let xAxisLabel = 'km';
  let yAxisLabel = 'm';
  // if (totalDistance > 100) {
  //     // Convert distances to miles
  //     distances = distances.map(d => d * 0.621371);
  //     xAxisLabel = 'mi';
  // }
  if (totalDistance < 5) {
    // Convert KM to meter
    distances = distances.map(d => d * 1000);
    xAxisLabel = 'm';
  }

  // Convert elevation to feet if needed
  // if (elevations.some(e => e > 1000)) {
  //     elevations = elevations.map(e => e * 3.28084);
  //     yAxisLabel = 'ft';
  // }

  return { elevations, distances, xAxisLabel, yAxisLabel }
}

export function determineZoom(dist: number) {
  if (dist < 1) return 14;
  if (dist < 5) return 13;
  if (dist < 10) return 12;
  if (dist < 50) return 11;
  if (dist < 100) return 10;
  if (dist < 500) return 9;
  return 8;
}


export function CalculateKmlDateandDuration(kmlData:any){
   try {
      let caseFor0minutes=false;
      let caseForNUllDates:any=false
       let Date=null
       let Duration=null
       let start=null
       let end= null
       let total_duration_minutes=0
       let file_created_at:any=""
       
      if(kmlData?.properties === undefined || kmlData?.properties?.description === undefined || kmlData?.properties?.description?.value === undefined   ){
        caseFor0minutes=caseForNUllDates=true
      }else{
        const DomParser= new DOMParser()
        const data=DomParser.parseFromString(kmlData?.properties?.description?.value,"text/html")
        
        const wholeTd=data.getElementsByTagName("tr")
         
        for(let i=0; i<wholeTd.length; i++){
          let tr= wholeTd[i]
          let tds= tr.getElementsByTagName("td")
        
          if(tds.length >=3){
            if(tds[0].innerText ==="Date"){
               Date= tds[2].innerText
               continue
            }
            if(tds[0].innerText==="Duration"){
               Duration= tds[2].innerText
               continue
            }
            if(tds[0].innerText==="Start"){
               start=tds[2].innerText
               continue
            }
            if(tds[0].innerText==="Finish"){
               end=tds[2].innerText
               continue
            }
          }
        }
        // here we will know if Date and Duration is present or not
        caseFor0minutes=Duration || (start && end) ? true :false
        caseForNUllDates = Date && true 
        
        if(!caseFor0minutes && !caseForNUllDates){
          return {total_duration_minutes:0,file_created_at:""}
        }
        // if(start  end){
        //   let startTime= start.split(" ")[1].split(":")
        //    let endTime=  end.split(" ")[1].split(":")

        // }
        if(Duration){
          total_duration_minutes=CalcualteHrsAndMInutes(Duration!)
        }
        if(Date){
          const dateObject = moment(Date, "DD-MM-YYYY");
           const timestamp = dateObject.unix();
           file_created_at={ _seconds:timestamp, _nanoseconds:0 }
        }
        return {total_duration_minutes,file_created_at}
      }
      
      return {total_duration_minutes:0,file_created_at:""}

   } catch (error) {
    
   }
}

function CalcualteHrsAndMInutes(data:string){
  // 2hrs 26minutes
  let hrs=0
  let mins=0
  let found:number| null=null
    for(let i=0; i<data.length; i++){
      let point=i
       while(!isNaN(+data[i])){
        found =i
          i++
       }
       if(data[i] === "h" && data[i+1] === "r"){
          hrs=+data.slice(point,found!+1)
          i++
          found=null
          continue;
       }
       if(data[i] === "m" && data[i+1] === "i"){
         mins=+data.slice(point,found!+1)
         i++
         found=null
         continue;
       }
    }
    
    //we get the hrs and minutes 
    return hrs * 60 + mins
    
}

export function convertTimestampToDateString(timestamp:any,format="YYYY-MM-DD") {
  

  if(timestamp==null || timestamp === undefined) return null
  // Combine the seconds and nanoseconds to create a full timestamp in milliseconds
  const timestampInMilliseconds = timestamp?._seconds * 1000;
  // Use Moment.js to format the timestamp into "yyyy-mm-dd" format
  const formattedDate = moment(timestampInMilliseconds).format(format);



  return formattedDate;
}


export function convertDateFormat(inputDate:string) {
  // Parse the input date and time using Moment.js
  const parsedDate = moment(inputDate);

  // Format the parsed date in "yyyy-mm-dd" format
  const formattedDate = parsedDate.format('YYYY-MM-DD');

  return formattedDate;
}


export function ConverTOUTCTimestamp(date:string){
  if(date === "") return ""
  const timestamp = new Date(date).getTime() / 1000; 
  return {_seconds:timestamp,_nanoseconds:0}
}

export const getTimeStampDate = (date:string) => {
  const momentObj = moment(date);

  // Get Unix timestamp in seconds
  const _seconds = momentObj.unix();

  // Get milliseconds and convert to nanoseconds
  // Since 1 millisecond = 1,000,000 nanoseconds
  const _nanoseconds = momentObj.millisecond() * 1000000;
  return { _seconds, _nanoseconds }
}

export function chunkArray(array: any, chunkSize: number) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

export function calculateIntersectionPoints(polyline: any) {
  const intersectionPoints: any = [];

  // Iterate through each segment of the polyline
  for (let i = 0; i < polyline.length - 1; i++) {
    const segmentStart = turf.point(polyline[i]);
    const segmentEnd = turf.point(polyline[i + 1]);
    const segment = turf.lineString([segmentStart.geometry.coordinates, segmentEnd.geometry.coordinates]);

    // Check for intersection with other segments
    for (let j = i + 2; j < polyline.length - 1; j++) {
      const otherSegmentStart = turf.point(polyline[j]);
      const otherSegmentEnd = turf.point(polyline[j + 1]);
      const otherSegment = turf.lineString([otherSegmentStart.geometry.coordinates, otherSegmentEnd.geometry.coordinates]);
      const intersection = turf.lineIntersect(segment, otherSegment);
      
      // If there is an intersection point, add it to the list
      if (intersection.features.length > 0) {
        intersection.features.forEach((feature) => {
          intersectionPoints.push(feature.geometry.coordinates);
        });
      }
    }
  }

  return intersectionPoints;
}

export const setLayer = (
    source: string, 
    layerData: any, 
    mapBox: any, 
    imgUrl: string | null, 
    linePatter: string,
    lineBottomlayer?: number[] | null,
    lineToplayer?: number[]
  ) => {
  const layers = layerData.map((e: any) => {
    return ({
      "type": "Feature",
      "geometry": {
        "type": "LineString",
        "coordinates": e.heatMapPoints.map((ev: number[]) => [ev[0], ev[1]])
      },
      "properties": {
        numActivities: e.number,
        strockRang: e.strockRang
      }
  })})
  const GEOJSON_DATA: any = {
    "type": "FeatureCollection",
    "features": layers
  };

  const isSource = mapBox.getSource(source);
  if(!isSource) {
    mapBox.addSource(source, {
      type: 'geojson',
      data: GEOJSON_DATA,
    });
    if(lineBottomlayer) {
      mapBox.addLayer({
        id: source + "line",
        type: 'line',
        source: source,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#7d1724', // Bottom Layer
          'line-blur': 0, // Adjust the blur value as needed
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            ...lineBottomlayer
          ]
        }
      });
    }
    if(imgUrl) {
      mapBox.loadImage(imgUrl, function(error: any, image: any) {
        if (error) return false;
        // Add the image to the map style
        if(!mapBox.hasImage(linePatter)) mapBox.addImage(linePatter, image);

        // Add a source and layer for the line with the pattern
        if(lineToplayer) {
          mapBox.addLayer({
              'id': source + "layer",
              'type': 'line',
              'source': source,
              'layout': {
                'line-join': 'round',
                'line-cap': 'round'
              },
              'paint': {
                  'line-pattern': linePatter, // use the pattern image for the line
                  'line-width': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    ...lineToplayer
                  ]
              }
          });
        }
      });
    }
  } else {
    const data = isSource._data;
    layers.forEach((layer: any) => {
      data.features.push(layer);
    })
    mapBox.getSource(source).setData(data);
  }

}

const TILE_SIZE = 256;
export const convertLatlngToPixel = (latLng: any, zoom: any) => {
  // const totalMapWorldDimention = {
  //   width: 256,
  //   height: 256
  // }
  const scale = 1 << zoom;
  const worldCoordinate = project(latLng, true);
  const pixelCoordinate = {
    x: Math.floor(worldCoordinate.x * scale),
    y: Math.floor(worldCoordinate.y * scale)
  };
  const tileCoordinate = {
    x: Math.floor((worldCoordinate.x * scale) / TILE_SIZE),
    y: Math.floor((worldCoordinate.y * scale) / TILE_SIZE)
  };

  console.log(worldCoordinate, "worldCoordinate")
  console.log(pixelCoordinate, "pixelCoordinate")
  console.log(tileCoordinate, "tileCoordinate")
}


export function project(latLng: any, isMap?: any) {
  // The mapping between latitude, longitude and pixels is defined by the web mercator projection.
  let siny = Math.sin((latLng.lat() * Math.PI) / 180);

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999);
  if(isMap) {
    return {
      x: TILE_SIZE * (0.5 + latLng.lng() / 360),
      y: TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
    }
  }
  return new google.maps.Point(
    TILE_SIZE * (0.5 + latLng.lng() / 360),
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)),
  );
}