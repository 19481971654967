import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import layoutReducer from "./reducers/layoutReducer";
import UploadFileReducer from "./reducers/fileReducers";
import tagReducer from "./reducers/tagReducer";
import activityReducer from "./reducers/activityReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutReducer,
    uploadfile: UploadFileReducer,
    tags:tagReducer,
    activity:activityReducer
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof configureStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
