import { Dropdown } from "react-bootstrap";
import { logout } from "store/actions/authAction";
// import { toggleSideBar } from "store/reducers/layoutReducer";
import { isDialogOpen } from "utils/CommonService";
import { useDispatch } from "react-redux";
import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";
import { Link } from "react-router-dom";
import { ROUTES_PATH } from "utils/constant";
// import bell from "assets/images/icons/bell-icon.png";
// import quemark from "../../../assets/images/icons/bell-icon.png";

const Header = () => {
  const dispatch = useDispatch();
  const { token,user } = useAppSelector((state: RootState) => state.auth);

  const handleConfirm = () => {
    isDialogOpen.onNext({
      open: true,
      data: {
        message: "Are you sure?",
        title: "Confirmation",
      },
      cancelText: "Cancel",
      confirmText: "Okay",
      onCancel: () => isDialogOpen.onNext(false),
      onConfirm: () => {
        isDialogOpen.onNext(false);
        logout(dispatch);
      },
    });
  };

  return (
    <header className="header">
      <div className="header_logo">
        <div className="logo">
          {/* <img src={logo} alt="logo" /> */}
          Savuti
        </div>
      </div>
      <div className="header_bar">
      { token ?
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className="toggle_btn">
            <div className="header_drop">
              <span>{user ? user?.first_name[0] : "A"}</span> {!user ? "Admin" : null} {user?.first_name}  {user?.last_name}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleConfirm}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
       : 
       <Link to={ROUTES_PATH.LOGIN} className="filter-btn btn-dark btn mt-3">Login</Link>
      }
      </div>
    </header>
  );
};
export default Header;
