// import { AxiosError } from "axios";
// import axios from "../index"
// import { store } from '../../../store/store';
// import { refreshToken } from "../../../store/actions/authAction";
import * as commonService from "../../CommonService"
// import { LoginRes } from "../../types";

// const handleAuthentication = async (error: any) => {
// 	const config = error.config
// 	if(config.url === "/refresh") {
// 		commonService.forError("Not authenticated");
// 		store.dispatch({ type: "auth/logout"})
// 		Promise.reject()
// 	} else {
// 		const res: any = refreshToken(store.dispatch)
// 		if (res && res.access_token) { 
// 			config.headers = {
// 				...config.headers, Authorization:  `Bearer ${res.access_token}`
// 			}
// 			return axios(config)
// 		}
// 		else {
// 			store.dispatch({ type: "auth/logout"})
// 			return false
// 		}
// 	}
// };

export default function errorHandler (error: any) {
	// if (error?.message === "Network Error") {
	// 	return handleAuthentication(error)
	// }
	const message = error?.response && error?.response?.data && error?.response?.data?.messages ? error?.response?.data?.messages : error?.message ? error?.message : 'Seems like something went wrong!' ;
	const code = error.response && error.response.data && error.response.data.code ? error.response.data.code : error.status
	switch (code) {
		case 400:
			// if (message === "jwt expired") handleAuthentication(error);
			// else
			 commonService.forErrorToast(message);
			break;
		case 401:
			// handleAuthentication(error);
			commonService.forErrorToast(message);
			break;
		case 500:
			commonService.forErrorToast(message);
			break;
		case 504:
			commonService.forErrorToast('Sorry, could not access the external resource to refine the data for your request, please try again later!');
			break;
		case 700:
			commonService.forErrorToast(message);
			break;
		default:
			commonService.forErrorToast(message ? message : 'something went wrong');
			break;
	}
	return Promise.reject(error);
}
