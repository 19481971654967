/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
// import { useAppSelector } from "store/hooks";
// import { RootState } from "store/store";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

function MyMap({ map }: any) {
  // const { files, uploadedGPXFiles } = useAppSelector((state: RootState) => state.uploadfile);


  // const setPathColor = (activities: any) => {
  //   const DENSITY_RADIUS = 100 // meter
  //   const allActivity = [...activities];
  //   const uniqArray = [];
  //   const duplicatIds: string[] = []
  //   for(let i=0; allActivity.length > i; i++) {
  //     const activity = allActivity[i];
  //     if(!duplicatIds.includes(activity.id)) {
  //       const start = activity.heatMapPoints[0];
  //       const end = activity.heatMapPoints[activity.heatMapPoints.length -1];
        
  //       const googleStart = new google.maps.LatLng(start[1], start[0]);
  //       const googleEnd = new google.maps.LatLng(end[1], end[0]);

  //       const otherActivities = activities.filter((e: any) => e.id !== activity.id);
  //       let count = 1;
  //       for (let act of otherActivities) {

  //         const actStartPoint = act.heatMapPoints[0];
  //         const actEndPoint = act.heatMapPoints[act.heatMapPoints.length - 1];

  //         const googleStartPoint = new google.maps.LatLng(actStartPoint[1], actStartPoint[0]);
  //         const googleEndPoint = new google.maps.LatLng(actEndPoint[1], actEndPoint[0]);

  //         const distanceToStart = google.maps.geometry.spherical.computeDistanceBetween(googleStartPoint, googleStart);
  //         const distanceToEnd = google.maps.geometry.spherical.computeDistanceBetween(googleEndPoint, googleEnd);
          
  //         if (distanceToStart < DENSITY_RADIUS && distanceToEnd < DENSITY_RADIUS && Math.abs(act?.total_distance_kilometre - activity?.total_distance_kilometre) <=10) {
          
  //           duplicatIds.push(act.id);
  //           count++
  //         }
  //       }
  //       uniqArray.push({...activity, number: count})
  //     }
  //   }
  //   const maxValue = uniqArray.length ? Math.max(...uniqArray.map((e: any) => e.number)) : 0;
  //   const minValue = uniqArray.length ? Math.min(...uniqArray.map((e: any) => e.number)) : 0;
  //   const finalActivities = uniqArray.map((e: any) => ({ ...e, maxValue: maxValue ? maxValue : 1, minValue: minValue ? minValue : 1, strockRang: (e.number / maxValue)*100 }))
  //   return finalActivities
  // }
  
  // const uniqPath: any = setPathColor(files)

  const YOUR_MAPBOX_TOKEN = "pk.eyJ1IjoiYWJkdWxiaXRjb3QiLCJhIjoiY2xvY2xxeTI5MGpnOTJpcXJhMDF5aXgxNyJ9.fezfv0nUB9Uwau4o_9Cs_Q"

  const mapContainer = useRef(null);

  mapboxgl.accessToken = YOUR_MAPBOX_TOKEN;

  useEffect(() => {
    if(mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        // style: 'mapbox://styles/mapbox/dark-v10', // example base map style
        style: 'mapbox://styles/abdulbitcot/cloh9lk05001g01qyblr97rsl', // example base map style
        center: [32.533207, 15.610796],
        zoom: 2,
        minZoom: 1,
        maxZoom: 17
      });
    }
  }, [mapContainer.current]);

  return <div ref={mapContainer} id="mapBox" />;
}
export default MyMap;