/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  
} from "react-bootstrap";

import { DatePicker } from 'rsuite';
import { useAppDispatch, useAppSelector } from "store/hooks";
import {  forSuccessToast } from "utils/CommonService";
import { UpdateCreatedDate } from "store/actions/formAction";
import { ThreeDots } from "react-loader-spinner";
import SimpleReactValidator from "simple-react-validator";
import { RootState } from "store/store";


const EditDateModal = ({
  showModal,
  setShowModal,
  child_id,
  mainId,
  id,
  date
}: any) => {
  const dispatch = useAppDispatch();
  const { filteredFiles }: any = useAppSelector((state: RootState) => state.uploadfile);
  const simpleValidator= useRef(new SimpleReactValidator())
  const [load,setLoad]=useState(false)
  const [createDate,setCreatedDate]=useState(date ? new Date(date?._seconds * 1000) : null)
  const [,forceUpdate]=useState(0)

  useEffect(()=>{
    setCreatedDate(date ? new Date(date?._seconds * 1000) : null)
  },[date])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement | HTMLElement>) => {
    e.preventDefault()
    if(!simpleValidator.current.allValid()){
     simpleValidator.current.showMessages()
     forceUpdate(1)
     return 
    }
    setLoad(true)
    const body={
        mainId,
        child_virtual_id:child_id,
        file_created_at:createDate,
        id
    }
    dispatch(UpdateCreatedDate(body)).then((res)=>{
        if(res){
           setLoad(false)
           forSuccessToast("Date changed succesfully")
        }
        setShowModal(false);
    }).finally(()=>{
        setCreatedDate(date ? new Date(date?._seconds * 1000) : null)
        setLoad(false)
    })
    
  };

  const disabledDate = (date: any) => {
    return date > new Date();
  }

  const closeModal = () => {
    setShowModal(false);
    setCreatedDate(date ? new Date(date?._seconds * 1000) : null)
  };

  const getTitle = (files: any, uid: any) => {
    const file = files.find((e: any) => e._id === uid);
    if(!file) return "";
    return file.title
  }

  return (
    <Modal show={showModal} onHide={closeModal} className="site-modal">
      <ModalHeader>
        <ModalTitle>Edit Created Date - {getTitle(filteredFiles, id)}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="modalbox_form">
          <div className="signup_innerf">
          <Form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label>Created Date</label>
               <div className="datePicker editDatePicker"> 
                  <DatePicker className='input_modify' placeholder="YYYY-MM-DD" format="yyyy-MM-dd" onChange={(d:any)=>{
                      setCreatedDate( d)
                  }} 

                  container={()=> document.getElementById("rendering-calender")!}
                  value={createDate}   
                  disabledDate={disabledDate}
                  //defaultValue={ dd ? new Date(dd) : null}
                  />
                </div>  
            <p style={{color:"red"}}>
            {
                simpleValidator.current.message("file_created_at",createDate,"required",{ messages: { required: 'This field is required' } })
            }
            </p>
            {/* <Col> */}
            {/* <div id="rendering-calender"></div> */}
            {/* </Col> */}
            </div>
              <ModalFooter className="modal-footer text-center border-0 p-0">
                <Button type="submit" className="btn btn-dark">
                    {
                        load ?  <ThreeDots
                        height="25"
                        width="45"
                        radius="9"
                        color="#000000"
                        ariaLabel="three-dots-loading"
                        visible={true}
                        wrapperStyle={{ justifyContent: "center" }}
                      /> :  "Submit"
                    }
                  
                </Button>
                <Button className="btn btn-light" onClick={closeModal}>
                  Close
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EditDateModal;
