import { addDocument, db, updateDocument } from "firebase-setup/setup";
import {
  Timestamp,
  and,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { AddNewActivity, DeleteActivityAction, UpdateActivityAction, addToActiveActivity } from "store/reducers/activityReducer";
import {
  AddAllActiveTags,
  AddNewTags,
  DeleteTagsAction,
  UpdateTagAction,
  addToActiveTags,
} from "store/reducers/tagReducer";
import { AppDispatch } from "store/store";
import { forErrorToast } from "utils/CommonService";
import {
  ACTIVESTATUS,
  CollectionNames,
  DELETEDSTATUS,
  TAGSFIELDS,
} from "utils/constant";

export const createTag = (form: any) => async (dispatch: AppDispatch) => {
  try {
    
    const documentData = {
      active: form.status,
      deleted_at: null,
      is_deleted: DELETEDSTATUS.notDeleted,
      status: ACTIVESTATUS.active,
      name: form.tagName,
      created_at: Timestamp.now(),
      updated_at: Timestamp.now(),
    };
    const newTag=await addDocument(documentData, CollectionNames.TAGS);
    dispatch(AddNewTags({id:newTag.id,...documentData}));
    return true;
  } catch (error: any) {
    console.log(error);
    forErrorToast(error?.code);
    return false;
  }
};


export const createActivity = (form: any) => async (dispatch: AppDispatch) => {
  try {
    
    const documentData = {
      active: Number(form.status),
      deleted_at: null,
      is_deleted: DELETEDSTATUS.notDeleted,
      status: ACTIVESTATUS.active,
      name: form.tagName,
      created_at: Timestamp.now(),
      updated_at: Timestamp.now(),
    };
    const newActivity=await addDocument(documentData, CollectionNames.ACTIVITY);
    dispatch(AddNewActivity({id:newActivity.id,...documentData}));
    return true;
  } catch (error: any) {
    console.log(error);
    forErrorToast(error?.code);
    return false;
  }
};

export const fetchActiveTags:any = () => async (dispatch: AppDispatch) => {
  try {
    const collectionRef = collection(db, CollectionNames.TAGS);
    const q = query(
      collectionRef,
      where(TAGSFIELDS.ISDELETED, "==", ACTIVESTATUS.disable),
      orderBy(TAGSFIELDS.CREATED_AT, "desc")
    );
    const activeTagsDocs = await getDocs(q);
    const activeTags = activeTagsDocs.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    if (activeTags.length) {
      dispatch(addToActiveTags(activeTags));
    }
  } catch (error: any) {
    console.log(error);
    forErrorToast(error.code);
  }
};

export const UpdateToggle =
  ({ id, status, index }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      // we need to update the db
      await updateDocument({ active: status }, CollectionNames.TAGS, id);
      dispatch(UpdateTagAction({ index, status }));
      return true;
    } catch (error: any) {
      console.log(error);
      forErrorToast(error?.code);
      return false;
    }
  };

export const DeleteTags = (id: string) => async (dispatch: AppDispatch) => {
  try {
    if (id) {
      await updateDocument(
        { active: DELETEDSTATUS.deleted, is_deleted: DELETEDSTATUS.deleted },
        CollectionNames.TAGS,
        id
      );
      dispatch(DeleteTagsAction(id));
      return true
    }
  } catch (error: any) {
    console.log(error);
    forErrorToast(error.code);
    return false;
  }
};

export const fetchAllActiveTags: any = () => async (dispatch: AppDispatch) => {
  try {
    const collectionRef = collection(db, CollectionNames.TAGS);
    const q = query(
      collectionRef,
      and(
        where(TAGSFIELDS.ISDELETED, "==", ACTIVESTATUS.disable),
        where(TAGSFIELDS.ACTIVE, "==", ACTIVESTATUS.active)
      ),
      orderBy(TAGSFIELDS.CREATED_AT, "desc")
    );
    const activeTagsDocs = await getDocs(q);
    const activeTags = activeTagsDocs.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    if (activeTags.length) {
      dispatch(AddAllActiveTags(activeTags));
    }
    return true;
  } catch (error: any) {
    console.log(error);
    forErrorToast(error?.code);
  }
};


export const fetchActiveActivity:any = () => async (dispatch: AppDispatch) => {
  try {
    const collectionRef = collection(db, CollectionNames.ACTIVITY);
    const q = query(
      collectionRef,
      where(TAGSFIELDS.ISDELETED, "==", ACTIVESTATUS.disable),
      orderBy(TAGSFIELDS.CREATED_AT, "desc")
    );
    const activeTagsDocs = await getDocs(q);
    const activeTags = activeTagsDocs.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    
    if (activeTags.length) {
      dispatch(addToActiveActivity(activeTags));
    }
  } catch (error: any) {
    console.log(error);
    forErrorToast(error.code);
  }
};
export const UpdateActivity =
  ({ id, status, index }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      // we need to update the db
      await updateDocument({ active: status }, CollectionNames.ACTIVITY, id);
      dispatch(UpdateActivityAction({ index, status }));
      return true;
    } catch (error: any) {
      console.log(error);
      forErrorToast(error?.code);
      return false;
    }
  };

  export const DeleteActivity= (id: string) => async (dispatch: AppDispatch) => {
    try {
      if (id) {
        
        await updateDocument(
          { active: DELETEDSTATUS.deleted, is_deleted: DELETEDSTATUS.deleted },
          CollectionNames.ACTIVITY,
          id
        );
        dispatch(DeleteActivityAction(id));
        return true
      }
    } catch (error: any) {
      console.log(error);
      forErrorToast(error.code);
      return false;
    }
  };
  